@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 聖ヶ丘の教育 #education_basis_index */
/* ============================================================ */
#education_basis_index {
	.pillar {
		&__item {
			width: 250px;
			height: 250px;
			@include g.radius(125);
			border: 2px solid g.$baseColorGreen;
			text-align: center;
			position: relative;
			margin: 0 auto 20px;
			padding: 50px 0 0;
			
			&__num {
				position: absolute;
				width: 80px;
				height: 80px;
				background: url("/common/img/common/bg_pattern01.png");
				@include g.radius(40);
				color: g.$white;
				font-size: 1.5rem;
				line-height: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				top: -35px;
				left: calc(50% - 44px);
				border: 5px solid g.$white;
				
				span {
					font-family: 'Arial',sans-serif;
					font-size: 3.0rem;
					color: #faeb42;
					display: block;
					margin: 5px 0 0;
				}
			}
			
			&__ttl {
				font-size: 2.2rem;
				font-weight: g.$font-medium;
				line-height: 1.4;
				color: g.$baseColorGreen;
			}
			
			&__pic {
				border: 2px solid #fff;
				position: absolute;
				bottom: 0;
				border-radius: 0 0 140px 140px;
				overflow: hidden;
			}
		}
	}
	
	.activities {
		&__item {
			background: url("/common/img/common/bg_pattern03.png");
			border: 3px solid #e9e9e9;
			margin: 0 0 25px;
			padding: 20px;
			
			&__pic {
				position: relative;
				margin: 0 0 30px;
				
				&::after {
					content: url("/common/img/education/basis/ico_activity.png");
					position: absolute;
					bottom: -30px;
					right: -20px;
				}
			}
			
			&__ttl {
				font-size: 1.8rem;
				font-weight: g.$font-medium;
				color: g.$baseColorGreen;
				margin: 0 0 10px;
				position: relative;
				z-index: 5;
				
				&::before {
					font-size: 6.0rem;
					color: #dfebe1;
					font-family: 'Arial',sans-serif;
					position: absolute;
					line-height: 80px;
					left: -10px;
					top: -25px;
					z-index: -1;
				}
			}
			
			&--01 {
				.activities__item__ttl::before {
					content: "01";
				}
			}
			&--02 {
				.activities__item__ttl::before {
					content: "02";
				}
			}
			&--03 {
				.activities__item__ttl::before {
					content: "03";
				}
			}
		}
	}
	
	.achitanq-summer {
		background: url("/common/img/education/basis/bg_achitanq-summer_sp.png") 0 0 no-repeat;
		background-size: contain;
		padding: 50% 0 0;
	}
}


/* ============================================================ */
/* 各教科の指導 #education_specialties_index */
/* ============================================================ */
#education_specialties_index {
	.specialties-visual {
		
		&__catch {
			background: url(/common/img/common/bg_pattern03.png);
			color: #2e7137;
			font-size: g.$m;
			box-shadow: 0 0 7px 3px rgba(0,0,0,.15);
			display: flex;
			margin: 15px 00;
			padding: 15px 25px;
			justify-content: center;
		}
	}
	.specialties-read {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: g.$font-medium;
		line-height: 1.4;
		font-size: g.$m;
		color: g.$baseColorGreen;
		margin: 0 0 30px;
		&::before,
		&::after {
			content: '';
			width: 2px;
			height: 80px;
			background-color: g.$baseColorGreen;
		}
		&::before {
			margin-right: 15px;
			transform: rotate(-15deg);
		}
		&::after {
			margin-left: 15px;
			transform: rotate(15deg);
		}
		&__ruby {
			line-height: 2.0;
			ruby {
				color: g.$red;
			}
			rt {
				font-size: g.$xxxs;
			}
		}
	}
}

/* ============================================================ */
/* A知探Qの夏 #education_activity_index */
/* ============================================================ */
#education_activity_index {
	.jhs-learning {
		&__course {
			&__item {
				border: 3px solid #e9e9e9;
				background: url("/common/img/common/bg_pattern03.png");
				color: g.$textColor;
				padding: 20px 15px;
				margin-bottom: 15px;
				
				.ttl06 {
					display: flex;
					align-items: center;
				}
				
				&__detail {
					&__img {
						margin: 0 0 10px;
					}
				}
			}
		}
	}
	.questlp-link {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		&__item {
			display: block;
			border: 1px solid #2e7137;
		}
		
	}
}

	
	
	
