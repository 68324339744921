@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: relative;
	z-index: 20;
	display: flex;
	border-bottom: 1px solid g.$borderColor;
	background: g.$white;
	padding: 40px 0 0;
	
	
	/* header-upper
	----------------------------------------------------------- */
	.header-upper {
		position: absolute;
		top: 0;
		width: 100%;
		height: 40px;
		background: g.$baseColorlighten;
		border-bottom: 1px solid g.$borderColor;
		&__list {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__item {
			height: 100%;
			font-size: 1.3rem;
			text-align: center;
			&:nth-child(2) {
				.__txt {
					border-right: 1px solid g.$borderColor;
					border-left: 1px solid g.$borderColor;
				}
			}
		}
		&__link {
			display: flex;
			align-items: center;
			height: 100%;
			color: g.$white;
			.__txt {
				padding: 0 15px;
				display: block;
			}
		}
	}
	
	
	/* header-logo
	----------------------------------------------------------- */
	.header-logo {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 60px;
		padding: 0 0 0 15px;
		
		&__link {
			display: flex;
			align-items: center;
			line-height: 1;
			
			&__pc {
				display: none;
			}
			
			&__tablet {
				display: none;
			}
			
			&__sp {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}


	/* hnav
	----------------------------------------------------------- */
	.hnav {
		flex: 0 0 auto;
		display: flex;
		
		&__item {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 15vw;
			height: 60px;
			border-left: 1px solid g.$borderColor;
			line-height: 1.5;
			
			&__icon {
				margin: 0 0 3px;
				font-size: g.$l;
			}
			&__text {
				color: g.$textColor;
				font-size: g.$xxxs;
			}
			&--menu {
				width: 16.6vw;
				background: url("/common/img/common/bg_pattern01.png");
				color: g.$white;
				cursor: pointer;
				
				.hnav__item__text {
					color: g.$white;
				}
			}
		}
	}


	/* gnav
	----------------------------------------------------------- */
	.gnav {
		position: absolute;
		top: 101px;
		left: 0;
		width: 100%;
		background: #2e7137;
		color: g.$white;
		opacity: 0;
		visibility: hidden;
		&__head,
		&__content1,
		&__content2 {
			&__item {
				display: flex;
				align-items: center;
				padding: 15px;
				border-bottom: 1px solid rgba(g.$white, 0.2);
				color: g.$white;
				line-height: 1.5;
				
				& > * {
					flex: 0 0 auto;
				}
				&__text {
					flex: 1 1 auto;
				}
				&.active {
					.icon-plus-square::before {
						content: "\f146";
					}
				}
			}
			&__lower {
				display: none;
				background: #103f15;
				
				&__link {
					display: block;
					padding: 15px 25px;
					border-bottom: 1px dotted rgba(g.$white, 0.2);
					color: g.$white;
				}
			}
		}
		&__head {
			background: #EBF6ED;
			&__item {
				padding: 10px 15px;
				border-bottom: 1px solid #C3E1C7;
				display: flex;
				justify-content: space-between;
				align-items: center;
				&__text {
					font-size: g.$m;
					font-weight: g.$font-medium;
					color: g.$baseColorGreen;
					line-height: 1.3;
					.__small {
						font-size: g.$xs;
						font-weight: g.$font-regular;
					}
				}
				&__btn {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
				.__btn {
					@include g.radius(3);
					height: 40px;
					color: g.$white;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: g.$font-regular;
					width: 80px;
					margin-left: 10px;
					&._js_ {
						background: g.$baseColorlighten;
						margin-left: 15px;
					}
					&._hs_ {
						background: g.$baseColorGreen;
					}
				}
				&._sns_ {
					justify-content: center;
				}
				.__ico {
					max-width: 45px;
					margin: 0 8px;
				}
			}
		}
		&__content4 {
			text-align: center;
			padding: 20px;
			&__item:not(:last-child) {
				display: block;
				margin: 0 0 15px;
			}
		}
		&__close {
			display: flex;
			align-items: center;
			padding: 15px;
			background: #282827;
		}
		
		.show & {
			opacity: 1;
			visibility: visible;
			z-index: 8000;
		}
	}
	
}