@charset "utf-8";

$baseColor: #ebf6ed;
$baseColorGreen: #2e7137;
$baseColorlighten: #4ba757;
$textColor: #333333;
$borderColor: #dcdcdc;

$white: #fff;
$red: #d03d3d;
$blue: #345fa6;
$pink: #e37aa4;
$orange: #e67836;
$purple: #834675;
$green: #12dc6c;
$yellow: #dbb617;
$lightblue: #594dff;

$gray: #dedede;
$dark-gray: #777;

/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;
$xxxxl: 2.6rem;


/* font-weight
-------------------------------------------------- */
$font-regular: 400;
$font-medium: 500;
