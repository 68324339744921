@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* footer */
/* ============================================================ */
/* footer-bnr
----------------------------------------------------------- */
.footer-bnr {
	&__wrap {
		max-width: 1240px;
		margin: 0 auto 8px;
		padding: 0 20px;
		&._sns_ {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 15px;
			grid-row-gap: 0px;
			grid-area: 1 / 1 / 2 / 4;
			max-width: 1240px;
		}
	}
	&__inner {
		margin: 0 -7.5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__item {
		max-width: 390px;
		margin: 0 7.5px;
		
		&:hover {
			opacity: .7;
		}
		._sns_ & {
			margin: 0;
			border: 1px solid #D6D7D7;
			padding: 15px 40px 15px 15px;
			display: flex;
			align-items: center;
			width: 100%;
			font-size: 1.3rem;
			color: g.$textColor;
			position: relative;
			height: 100%;
			.__img {
				width: 50px;
				margin-right: 15px;
			}
			.__ico{
				right: 15px;
				@include g.centering-elements(false, true);
				color: #BABABA;
				font-size: g.$m;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}


footer {
	background: url("/common/img/common/bg_footer.jpg") right bottom no-repeat;
	background-size: cover;
	padding: 50px 0 0;
	
	@media screen and (min-width: 1400px) and (max-width: 1640px) {
		background-size: 100%;
	}
	
	@media screen and (min-width: 767.5px) and (max-width: 1400px) {
		background-size: 110%;
	}

	/* fnav
	----------------------------------------------------------- */
	.fnav {
		margin: 0 0 160px;
		line-height: 1.5;
		
		&__inner {
			display: flex;
			max-width: 1320px;
			margin: 0 auto;
			padding: 0 60px;
			
			@media screen and (max-width: 1515px) {
				padding: 0 20px;
			}
		}
		&__level1 {
			width: 25%;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 15px;
				}
				&__link {
					color: g.$textColor;
					font-size: g.$m;
					font-weight: g.$font-medium;
				}
			}
		}
		&__level2 {
			margin: 15px 0 0;
			
			&__item {
				&:not(:last-child) {
					margin: 0 0 3px;
				}
				&::before {
					content: "-";
					margin: 0 5px 0 0;
				}
				
				&__link {
					color: g.$textColor;
					font-size: g.$xs;
				}
			}
		}
	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		padding: 40px 0;
		background: rgba(0,0,0,.8);
		
		@media screen and (max-width: 1515px) {
			padding: 40px 20px;
		}
		
		&__inner {
			display: flex;
			align-items: flex-end;
			max-width: 1240px;
			margin: 0 auto;
		}
		
		&__logo {
			display: flex;
			align-items: center;
			margin: 0 0 20px;
			width: 235px;
		}
		&__address {
			color: g.$white;
			font-size: g.$xs;
			line-height: 1.6;
		}
		&__right {
			margin-left: auto;
			color: #c1c1c1;
			font-size: 1.3rem;
		}
	}
	/* footer-sns
	----------------------------------------------------------- */
    .footer-sns {
        font-size: 2.8rem;
        &__link {
            color: g.$white;
            text-decoration: none;
            display: inline-block;
            margin-right: 10px;
            @include g.transition;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}