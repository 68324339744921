@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 過去の入試結果　#student_junior-past */
/* ============================================================ */
#student_junior-past {
	.table {
		th,td {
			padding: 15px 10px;
		}
	}
}
/* ============================================================ */
/* よくある質問　#student_faq */
/* ============================================================ */
#student_faq {
	.faq-accordion {
		margin: 0 0 15px;
		border-bottom: 1px solid #d8dec3;
		color: g.$baseColorGreen;
		font-weight: g.$font-medium;
		line-height: 1.4;
		background: none;
		font-size: g.$xl;
		display: flex;
		align-items: center;
		padding: 0 30px 15px 0;
		&::before {
			content: "Q";
			background: g.$baseColorGreen;
			color: g.$white;
			width: 45px;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 20px 0 0;
			flex: 0 0 45px;
		}
		.accordion__icon {
			color: #777777;
			font-size: g.$xxxl;
		}
		&.active {
			.accordion__icon::before {
				content: "\f056";
			}
		}
	}
	.faq-accordion-hide {
		margin: 0 0 50px;
		padding: 0;
		&__txt {
			display: flex;
			&::before {
				content: "A";
				border: 1px solid g.$baseColorGreen;
				color: g.$baseColorGreen;
				flex: 0 0 45px;
				width: 45px;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 20px 0 0;
			}			
		}
	}
}