@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* footer */
/* ============================================================ */
/* footer-bnr
----------------------------------------------------------- */
.footer-bnr {
	padding: 40px 0;
	&__wrap {
		padding: 0 15px;
		
	}

	&__item {
		display: block;
		margin: 15px 0;
		&:first-child {
			margin: 0 0 15px;
		}
		._sns_ & {
			border: 1px solid #D6D7D7;
			padding: 10px 35px 10px 10px;
			display: flex;
			align-items: center;
			width: 100%;
			font-size: 1.3rem;
			color: g.$textColor;
			position: relative;
			height: 100%;
			.__img {
				width: 50px;
				margin-right: 10px;
			}
			.__ico{
				right: 15px;
				@include g.centering-elements(false, true);
				color: #BABABA;
				font-size: g.$m;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}


footer {
	display: flex;
	flex-direction: column;

	/* fnav
	----------------------------------------------------------- */
	.fnav {
		border-top: 1px solid g.$borderColor;

		a {
			color: g.$textColor;
		}
		
		&__level1 {
			&__item {
				border-bottom: 1px solid g.$borderColor;
				
				&__link {
					display: flex;
					align-items: center;
					padding: 10px 15px;
					
					[class^="icon-angle-"] {
						margin: 0 8px 0 0;
					}
					&.active {
						.icon-angle-down {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		
		&__level2 {
			display: none;
			background: #f0f0f0;
			
			&__item {
				border-top: 1px dotted g.$borderColor;
				
				&__link {
					display: block;
					padding: 10px 15px 10px 30px;
					color: g.$textColor !important;
					
					&::before {
						content: "-";
						margin: 0 5px 0 0;
					}
				}
			}
		}
	}


	/* footer-content
	----------------------------------------------------------- */
	.footer-content {
		background: url("/common/img/common/bg_footer.jpg") right bottom no-repeat;
		background-size: 150%;
		padding: 25px 0 0;
		min-height: 0.1px;
		
		&__logo {
			display: flex;
			align-items: center;
			margin: 0 auto 10px;
			width: 155px;
		}
		&__address {
			color: g.$textColor;
			font-size: g.$xxs;
			text-align: center;
			line-height: 1.4;
			margin: 0 0 10px;
		}
		&__right {
			margin-left: auto;
			color: #c1c1c1;
			font-size: 1.3rem;
			
			small {
				font-size: g.$xxxs;
				text-align: center;
				display: block;
				background: rgba(0,0,0,.8);
				padding: 10px 0;
				line-height: 1.4;
			}
		}
		&__btn {
			display: flex;
			justify-content: center;
			margin: 0 0 25px;
			
			&__item {
				border: 1px solid g.$borderColor;
				background: g.$white;
				width: 55px;
				height: 55px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 30px;
				margin: 0 5px;
				
				&__text {
					color: g.$textColor;
					font-size: g.$xxxs;
				}
			}
		}
	}
    /* footer-sns
	----------------------------------------------------------- */
    .footer-sns {
        font-size: 2.8rem;
        text-align: center;
        margin: 0 0 5px;
        &__link {
            text-decoration: none;
            display: inline-block;
            margin-right: 10px;
            @include g.transition;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}