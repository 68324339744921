@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 学校長挨拶 #introduction_message_index */
/* ============================================================ */
#introduction_message_index {
	@media screen and (max-width: 1350px) {
		#history {
			.table {
				.history-year {
					white-space: nowrap;
				}
			}
		}
	}
}




