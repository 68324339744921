$lity-z-index-base: 9990;
$lity-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
$lity-close-size: 35px;
$lity-close-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
$lity-iframe-ratio: 9/16; // Or 3/4
$lity-transition-timing-function: ease;
$lity-transition-duration: .3s;

.lity {
	z-index: $lity-z-index-base;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	white-space: nowrap;
	background: #0b0b0b;
	background: rgba(0, 0, 0, 0.9);
	outline: none !important;
	opacity: 0;
	//transition: opacity $lity-transition-duration $lity-transition-timing-function;

	&.lity-opened {
		opacity: 1;
	}

	&.lity-closed {
		opacity: 0;
	}

	* {
		box-sizing: border-box;
	}
}
.lity-wrap {
	z-index: $lity-z-index-base;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	outline: none !important;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em;
	}
}

.lity-loader {
	z-index: $lity-z-index-base + 1;
	color: #fff;
	position: absolute;
	top: 50%;
	margin-top: -0.8em;
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-family: Arial, Helvetica, sans-serif;
	opacity: 0;
	transition: opacity $lity-transition-duration $lity-transition-timing-function;

	.lity-loading & {
		opacity: 1;
	}
}

.lity-container {
	z-index: $lity-z-index-base + 2;
	position: relative;
	text-align: left;
	vertical-align: middle;
	display: inline-block;
	white-space: normal;
	max-width: 100%;
	max-height: 100%;
	outline: none !important;
}

.lity-content {
	z-index: $lity-z-index-base + 3;
	width: 100%;

	transform: scale(1);
	transition: transform $lity-transition-duration $lity-transition-timing-function;

	.lity-loading &,
	.lity-closed & {
		transform: scale(0.8);
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		display: block;
		right: 0;
		width: auto;
		height: auto;
		z-index: -1;
		box-shadow: $lity-shadow;
	}
}

.lity-close-style {
	text-decoration: none;
	text-align: center;
	padding: 0;
	color: #fff;
	font-style: normal;
	font-size: $lity-close-size;
	font-family: Arial, Baskerville, monospace;
	line-height: $lity-close-size;
	text-shadow: $lity-close-text-shadow;
	border: 0;
	background: none;
	outline: none;
	box-shadow: none;
}


.lity-close {
	z-index: $lity-z-index-base + 4;
	width: $lity-close-size;
	height: $lity-close-size;

	position: fixed;
	right: 0;
	top: 0;

	-webkit-appearance: none;
	cursor: pointer;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&:active {
		top: 1px;
	}
}

.lity-image {
	img {
		max-width: 100%;
		display: block;
		line-height: 0;
		border: 0;
	}
}

.lity-iframe,
.lity-youtube,
.lity-vimeo,
.lity-facebookvideo,
.lity-googlemaps {
	.lity-container {
		width: 100%;
		max-width: 964px;
	}
}

.lity-iframe-container {
	width: 100%;
	height: 0;
	padding-top: $lity-iframe-ratio * 100%;
	overflow: auto;
	pointer-events: auto;
	transform: translateZ(0);
	-webkit-overflow-scrolling: touch;

	iframe {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: $lity-shadow;
		background: #000;
	}
}

.lity-hide {
	display: none;
}
