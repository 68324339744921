@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	flex: 0 0 auto;
	background: g.$white;
	
	@media screen and (min-width: 1280px) {
		width: 220px;
		@include g.box-shadow;
		z-index: 3;
	}
	@media screen and (max-width: 1279.5px) {
		display: flex;
		align-items: center;
		height: 95px;
		border-bottom: 1px solid g.$borderColor;
	}
	
	
	/* header-logo
	----------------------------------------------------------- */
	.header-logo {
		background: url("/common/img/common/bg_logo.png") center center repeat;
				
		@media screen and (min-width: 1280px) {
			display: flex;
			align-items: center;
			height: 210px;
		}
		@media screen and (max-width: 1279.5px) {
			order: 2;
			background: none;
		}
		
		&__link {
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 100%;
			margin: 0 auto;

			@media screen and (max-width: 1279.5px) {
				flex-direction: row;
			}
			
			& > * {
				flex: 0 0 auto;
			}
			
			&__pc {
				width: 100%;
				
				@media screen and (max-width: 1279.5px) {
					display: none;
				}
			}
			
			&__tablet {
				display: none;
				
				@media screen and (max-width: 1279.5px) {
					display: block;
				}
			}
			
			&__sp {
				display: none;
			}
		}
	}


	/* hnav
	----------------------------------------------------------- */
	.hnav {
		@media screen and (max-width: 1279.5px) {
			order: 1;
			margin: 0 20px 0 0;
		}
		.hnav__item--menu {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 95px;
			background: g.$baseColor;
			color: g.$baseColorGreen;
			font-size: 3.0rem;
			cursor: pointer;
			
			.hnav__item__text {
				display: none;
			}
			
			@media screen and (min-width: 1280px) {
				display: none;
			}
		}
	}


	/* gnav
	----------------------------------------------------------- */
	.gnav {
		@media screen and (max-width: 1279.5px) {
			position: absolute;
			top: 95px;
			bottom: 0;
			left: 0;
			z-index: 10;
			width: 220px;
			background: g.$white;
			border-right: 1px solid g.$borderColor;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-100%);
			@include g.transition;
		}
		.show & {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
		
		&__content1 {
			margin: 23px 0;
			
			&__body {
				&:hover {
					.gnav__content1__item {
						color: g.$baseColorGreen;

						.gnav__content1__item__text {
							&::before {
								border-right: 3px solid g.$baseColorlighten;
							}
						}
					}
				}
			}
			&__item {
				display: block;
				padding: 10px 30px;
				color: g.$textColor;
				font-size: 1.7rem;
				font-weight: bold;
				cursor: pointer;
				@include g.transition;
				position: relative;
				
				&__text {
					&::before {
						content: "";
						width: 3px;
						height: 40px;
						border-right: 3px solid transparent;
						display: block;
						position: absolute;
						right: 0;
						top: 15px;
						transition: .3s all;
					}
					
					&::after {
						display: block;
						content: attr(data-text);
						font-family: 'Noto Sans JP', sans-serif;
						color: g.$baseColorlighten;
						font-weight: normal;
						font-size: g.$s;
						line-height: 1.25;
					}
				}
				
				&.current,
				&:hover {
					color: g.$baseColorGreen;
					
					.gnav__content1__item__text {
						&::before {
							border-right: 3px solid g.$baseColorlighten;
						}
					}
				}
			}
			&__lower {
				display: none;
				background: url("/common/img/common/bg_stripe01.png") 0 0 repeat;
				color: g.$textColor;
				
				@media screen and (max-width: 1279.5px) { padding: 20px 0; }

				&__ttl {
					margin: 0 0 20px;
					font-size: g.$l;
					font-weight: g.$font-medium;
					
					&:not(:first-child) {
						margin-top: 50px;
					}
				}
				&__link {
					display: block;
					padding: 12px 20px 12px 30px;
					color: g.$textColor;
					line-height: 1.6;
					border-left: 3px solid transparent;
					@include g.transition;
					
					&::before {
						content: "-";
						margin: 0 5px 0 0;
					}
					
					&.current,
					&:hover {
						border-left: 3px solid #4ba757;
						background: rgba(57, 136, 68, 0.15);
						color: g.$baseColorGreen;
						font-weight: 500;
					}
				}
			}
		}
		
		&__content2 {
			background: url("/common/img/common/bg_stripe03.png") 0 0 repeat;
			padding: 30px;
			
			&__item {
				display: block;
				color: g.$textColor;
				font-size: 1.5rem;
				line-height: 1.6;
				margin: 0 0 10px;
				&._btn_ {
					border: 1px solid g.$baseColorlighten;
					text-align: center;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include g.radius(3);
					color: g.$baseColorlighten;
					background: g.$white;
					position: relative;
					.icon-angle-right {
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translate(0, -50%);
						font-size: 1.4rem;
					}
				}
				&:hover {
					opacity: 0.7;
				}
			}
			
			&__btn {
				color: g.$white;
				font-size: 1.7rem;
				height: 55px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: url("/common/img/common/bg_pattern01.png") 0 0 repeat;
				margin: 0 0 10px;
				@include g.radius(3);
				position: relative;
				
				.icon-angle-right {
					font-size: g.$s;
					right: 10px;
					@include g.centering-elements(false, true);
				}
				
				&:hover {
					opacity: .75;
				}
			}
		}
		
		&__content3,
		&__content4 {
			margin: 30px 23px 0 21px;
			
			&__item {
				display: block;
				margin: 0 0 20px;
				
				&:hover {
					@include g.opacity;
				}
			}
		}
		&__content4 {
			margin: 0 23px 0 21px;
		}
	}
	
	
	/* header-upper
	----------------------------------------------------------- */
	.header-upper {
		@media screen and (min-width: 1280px) {
			position: absolute;
			top: 0;
			left: 220px;
			right: 0;
			background: g.$white;
			border-bottom: 1px solid g.$borderColor;
		}
		@media screen and (max-width: 1279.5px) {
			order: 3;
			padding: 0 10px 0 0;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}

		&__inner {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			
			@media screen and (min-width: 1280px) {
				max-width: 1240px;
				height: 95px;
				margin: 0 auto;
				padding: 18px 20px;
			}
			@media screen and (max-width: 1279.5px) {
				padding: 12px 0;
				height: 100%;
			}
		}
		&__sns {
			margin: 0 0 0 15px;
			.__ico {
				max-width: 50px;
				margin: 0 0 0 10px;
				display: inline-block;
				&:hover {
					@include g.opacity;
				}
			}
			@media screen and (max-width: 1279.5px) {
				margin: 0 0 0 10px;
				.__ico {
					max-width: 35px;
					margin: 0 0 0 5px;
				}
			}
		}
		&__bg {
			height: 100%;
			background: url("/common/img/common/bg_stripe03.png") repeat;
			@include g.radius(3);
			padding: 10px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: g.$baseColorlighten;
			font-weight: g.$font-medium;
			margin: 0 0 0 10px;
			.__txt {
				margin: 0;
				line-height: 1.1;
				font-size: g.$m;
				text-align: center;
				.__small {
					font-size: 1.1rem;
					font-weight: g.$font-regular;
				}
			}
			
			@media screen and (max-width: 1279.5px) {
				display: flex;
				justify-content: center;
				flex-direction: column;
				text-align: center;
				font-size: g.$s;
				padding: 6px 13px;
				.__txt {
					font-size: g.$s;
					.__small {
						font-size: 1.0rem;
					}
				}
			}
			@media screen and (max-width: 825px) {
				.__btn {
					width: auto;
					padding: 0 10px;
				}
			}
		}
		&__btn {
			display: flex;
			.__btn {
				@include g.radius(3);
				height: 100%;
				color: g.$white;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: g.$font-regular;
				width: 80px;
				margin-left: 5px;
				&._js_ {
					background: g.$baseColorlighten;
					margin-left: 15px;
				}
				&._hs_ {
					background: g.$baseColorGreen;
				}
				&:hover {
					@include g.opacity;
					text-decoration: none;
				}
			}
			@media screen and (max-width: 1279.5px) {
				.__btn {
					margin: 5px 0 0 5px;
					height: 25px;
					width: 55px;
					display: inline-flex;
					&._js_ {
						margin-left: 0;
					}
				}
			}
		}
	}
}


/* ============================================================ */
/* 下層ページのgnaviのactive設定 */
/* ============================================================ */
#index .gnav__content1__item--home,
.introduction .gnav__content1__item--introduction,
.education .gnav__content1__item--education,
.school .gnav__content1__item--school,
.course .gnav__content1__item--course,
.student .gnav__content1__item--student,
.access .gnav__content1__item--access {
	color: g.$baseColorGreen;

	.gnav__content1__item__text {
		&::before {
			border-right: 3px solid g.$baseColorlighten;
		}
	}
}

