@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 聖ヶ丘の一日　#school_day_index */
/* ============================================================ */
#school_day_index {
	
	.day {
		&__item {
			display: flex;
			
			&__time {
				flex: 0 0 auto;
				text-align: center;
				width: 85px;
				margin: 0 35px 0 0;
				position: relative;
				
				&__text {
					background: g.$baseColorGreen;
					color: g.$white;
					font-size: g.$xxs;
					padding: 3px 15px;
					@include g.radius(20);
				}

				&::before {
					content: "";
					width: 5px;
					height: 100%;
					border-left: 5px solid #e6f5e9;
					position: absolute;
					left: 40px;
					top: 0;
					z-index: -1;
				}
			}
			
			&__balloon {
				flex: 1 1 auto;
				display: flex;
				background: url("/common/img/common/bg_pattern03.png");
				margin: 0 0 50px;
				padding: 25px;
				@include g.box-shadow;
				
				&__pic {
					flex: 0 0 auto;
					width: 40%;
					margin: 0 25px 0 0;
				}
				
				&__text {
					flex: 1 1 auto;
				}
			}
			
			&__ttl {
				color: g.$baseColorGreen;
				font-weight: g.$font-medium;
				font-size: g.$xxl;
				line-height: 1.4;
				margin: 0 0 10px;
			}
			
				
			&:last-child {
				.day__item__time::before {
					display: none;
				}
			}
		}
	}
}


/* ============================================================ */
/* 学校行事　#school_event_index */
/* ============================================================ */
#school_event_index {
	.event {
		&__item {
			padding-left: 70px;
			position: relative;
			
			&::before {
				content: "";
				width: 3px;
				height: 100%;
				border-left: 3px solid #bbdec0;
				position: absolute;
				left: 30px;
				top: 0;
			}
			
			&::after {
				content: "";
				width: 10px;
				height: 10px;
				@include g.radius(5);
				position: absolute;
				left: 27px;
				top: 45px;
				background: g.$baseColorGreen;
			}
		}
	}
}

/* ============================================================ */
/* 聖SSR　#school_ssr_index */
/* ============================================================ */
#school_ssr_index {
	.ssr {
        margin: 45px 0 0 0;
        &__item {
			background: url("/common/img/common/bg_pattern03.png");
			border: 3px solid #e9e9e9;
			margin: 0 0 40px;
			padding: 40px 50px;
        }
    }
}




