@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 聖ヶ丘の一日　#school_day_index */
/* ============================================================ */
#school_day_index {
	.day {
		&__item {
			margin: 0 0 50px;
			position: relative;
			
			&::after {
				position: absolute;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 18px 17.5px 0 17.5px;
				border-color: g.$baseColorGreen transparent transparent transparent;
				bottom: -35px;
				left: calc(50% - 18px);
			}
			
			&:last-child {
				&::after {
					display: none;
				}
			}
			
			&__time {
				display: none;
			}
			
			&__balloon {
				display: flex;
				flex-direction: column-reverse;
				background: url("/common/img/common/bg_pattern03.png");
				padding: 15px 25px 25px;
				@include g.box-shadow;
				
				&__pic {
					margin: 15px 0 0;
				}
			}
			
			&__ttl {
				color: g.$baseColorGreen;
				font-weight: g.$font-medium;
				font-size: g.$xl;
				line-height: 1.4;
				margin: 0 0 5px;
			}
		}
	}
}


/* ============================================================ */
/* #school_event_index */
/* ============================================================ */
#school_event_index {
	.event {
		&__item {
			padding: 20px 20px 20px 50px;
			position: relative;
			
			&::before {
				content: "";
				width: 3px;
				height: 100%;
				border-left: 3px solid #bbdec0;
				position: absolute;
				left: 20px;
				top: 0;
			}
			
			&::after {
				content: "";
				width: 10px;
				height: 10px;
				@include g.radius(5);
				position: absolute;
				left: 16px;
				top: 30px;
				background: g.$baseColorGreen;
			}
		}
	}
}
	
/* ============================================================ */
/* 聖SSR　#school_ssr_index */
/* ============================================================ */
#school_ssr_index {
	.ssr {
        margin: 45px 0 0 0;
        &__item {
			background: url("/common/img/common/bg_pattern03.png");
			border: 3px solid #e9e9e9;
			margin: 0 0 25px;
			padding: 20px;
        }
    }
}
	
