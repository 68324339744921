@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	position: relative;
	z-index: 1;
		
	&--column1 {
		.contents__inner {
			margin: 60px auto;
			padding: 0 60px;
			max-width: 1320px;
			
			@media screen and (max-width: 1490px) {
				margin: 60px 0;
			}
			
			@media screen and (max-width: 959.5px) {
				display: block;
				margin: 60px 20px;
				padding: 0;
			}
		}
		.main {
			flex: 1 1 0%;
			min-width: 0;
			
			@media screen and (max-width: 959.5px) {
				margin: 0 0 40px;
			}
		}
	}
		
	&--column2 {
		.contents__inner {
			display: flex;
			justify-content: space-between;
			margin: 60px auto;
			padding: 0 60px;
			max-width: 1320px;
			
			@media screen and (max-width: 1490px) {
				margin: 60px 0;
				padding: 0 60px;
			}
			
			@media screen and (max-width: 959.5px) {
				display: block;
				margin: 60px 0;
				padding: 0 20px;
			}
		}
		.main {
			flex: 1 1 0%;
			min-width: 0;
			margin: 0 50px 0 0;
			
			@media screen and (max-width: 959.5px) {
				margin: 0 0 40px;
			}
		}
		.side {
			flex: 0 0 auto;
			width: 230px;
			
			@media screen and (max-width: 959.5px) {
				width: 100%;
			}
		}
	}
	
	&__footer {
		padding: 50px 0 0;
	}
}

.row {
	padding: 60px 0;
	
	&--green {
		background: g.$baseColor;
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	color: g.$baseColorGreen;
	font-size: g.$xxl;
	font-weight: g.$font-medium;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
	color: g.$baseColorGreen;
	font-size: 3.6rem;
	font-weight: g.$font-medium;
	line-height: 1.5;
	@include g.literal;
	position: relative;
		
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 1320px;
		height: 210px;
		margin: 0 auto;
		padding: 0 60px;
		
		@media screen and (max-width: 1279.5px) {
			height: 200px;
		}
		@media screen and (max-width: 959.5px) {
			padding: 0 20px;
		}
	}
	
	&__category-sub {
		font-size: g.$xl;
		font-weight: g.$font-regular;
		color: #5b5b5b;
	}
}

.ttl02 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 40px;
	padding: 0 0 20px;
	color: g.$baseColorGreen;
	font-size: 3.0rem;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.3;
	@include g.literal;
	
	&::after {
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 2px solid g.$baseColorGreen;
		@include g.centering-elements(true, false);
	}
	&__sub-ttl {
		color: g.$textColor;
		font-size: g.$l;
		font-weight: g.$font-regular;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 30px;
	font-size: 2.6rem;
	font-weight: g.$font-medium;
	line-height: 1.4;
	text-align: center;
	@include g.literal;
	
	&::after {
		display: block;
		content: attr(data-text);
		margin: 5px 0 0;
		color: g.$baseColorGreen;
		font-size: g.$xxs;
		text-transform: uppercase;
		letter-spacing: 0;
		@include g.raleway;
	}
}

.ttl04 {
	margin: 0 0 25px;
	padding: 20px 0;
	border-top: 1px solid g.$baseColorGreen;
	border-bottom: 1px solid #d8dec3;
	color: g.$baseColorGreen;
	font-size: g.$xxl;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: g.$baseColor;
			font-size: g.$xxxl;
		}
	}
}

.ttl05 {
	margin: 0 0 25px;
	padding: 12px 20px;
	background: g.$baseColor;
	font-size: 1.9rem;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
	
	&.accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: g.$baseColor;
		}
	}
}

.ttl06 {
	margin: 0 0 20px;
	padding: 0 0 0 15px;
	border-left: 2px solid g.$baseColorGreen;
	color: g.$baseColorGreen;
	font-size: 1.7rem;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
}

.ttl07 {
	position: relative;
	margin: 0 0 30px;
	padding: 25px 0 0;
	color: g.$baseColorGreen;
	font-size: 3.0rem;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.4;
	@include g.literal;
	
	&::before {
		top: 0;
		display: block;
		content: '';
		width: 60px;
		height: 2px;
		background: g.$baseColorGreen;
		@include g.centering-elements(true, false);
	}
}

.ttl08 {
	margin: 0 0 30px;
	color: g.$orange;
	font-size: 2.6rem;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.4;
	@include g.literal;
}

.underline {
	font-weight: g.$font-medium;
	background: linear-gradient(transparent 60%, #FFEB68 60%);
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;
	word-break: break-all;
	word-wrap: break-word;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -20px;
	 margin-right: -20px;

		&> * {
			margin-left: 20px;
			margin-right: 20px;
			width: calc((100% - 81px) / 2);
		}
		&--thin {
		 margin-left: -5px;
		 margin-right: -5px;
			
		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 21px) / 2);
		}
	}
}

.column3 {
	margin-left: -15px;
	margin-right: -15px;

	&> * {
		margin-left: 15px;
		margin-right: 15px;
		width: calc((100% - 91px) / 3);
	}
	&--thin {
	 margin-left: -6px;
	 margin-right: -6px;

		&> * {
			margin-left: 6px;
			margin-right: 6px;
			width: calc((100% - 37px) / 3);
		}
	}
}

.column4 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 81px) / 4);
	}
	&--thin {
	 margin-left: -5px;
	 margin-right: -5px;

		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 41px) / 4);
		}
	}
}

.column5 {
	margin-left: -5px;
	margin-right: -5px;

	&> * {
		margin-left: 5px;
		margin-right: 5px;
		width: calc((100% - 51px) / 5);
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	word-break: break-all;
	word-wrap: break-word;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}
	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	word-break: break-all;
	word-wrap: break-word;

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
	
	.img-column2 {
		display: flex;
		justify-content: space-between;
		
		* {
			margin: 0 10px;
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 5px;
			height: 5px;
			display: block;
			position: absolute;
			top: 0.75em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				left: 2px;
				width: 4px;
				height: 4px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: g.$textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: g.$baseColorGreen;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 30px;
	box-sizing: border-box;
	background: g.$white;

	&--border-green {
		border: 2px solid g.$baseColorGreen;
	}
	&--border-gray {
		border: 1px solid g.$borderColor;
	}
	&--green {
		background: g.$baseColor;
	}
	&--gray {
		background: #f3f3f3;
	}
	&--yellow {
		background: #f9f3d8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 15px;
		background: g.$white;
		border: 1px solid #dbdbdb;
		font-size: g.$s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-green {
		background: g.$baseColorGreen;
		color: g.$white;
	}
	.bg-green-lighten {
		background: g.$baseColor;
	}
	.bg-gray {
		background: #f6f6f6;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 205px;
	height: 50px;
	padding: 10px 25px;
	border-style: solid;
	border-width: 1px;
	color: g.$textColor;
	font-size: 1.7rem;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	@include g.radius(3);
	@include g.transition;

	.icon-fix-left,
	.icon-fix-right {
		font-size: g.$xl;
		@include g.centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	&:hover {
		text-decoration: none;
	}
	
	&--green {
		background: g.$baseColorGreen;
		border-color: g.$baseColorGreen;
		color: g.$white;
		
		&:hover {
			background: g.$white;
			color: g.$baseColorGreen;
		}
	}
	&--black {
		background: #2a2a2a;
		border-color: #2a2a2a;
		color: g.$white;
		
		&:hover {
			background: g.$white;
			color: #2a2a2a;
		}
	}
	&--red {
		background: g.$red;
		border-color: g.$red;
		color: g.$white;
		
		&:hover {
			background: g.$white;
			color: g.$red;
		}
	}
	&--gray {
		background: g.$gray;
		border-color: g.$gray;
		
		&:hover {
			background: g.$white;
		}
	}
	&--check-green {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		border-color: g.$baseColorGreen;
		color: g.$white;
		
		&:hover {
			opacity: .75;
		}
	}
	&--check-black {
		background: url("/common/img/common/bg_pattern05.png");
		border-color: #2a2a2a;
		color: g.$white;
		
		&:hover {
			opacity: .75;
		}
	}
	&--lg {
		min-width: 250px;
		height: 55px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
		font-size: g.$m;
		
		.icon-fix-left,
		.icon-fix-right {
			font-size: g.$m;
		}
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: g.$textColor;
			@include g.radius(3);
			
			&.current,
			&:hover {
				background: g.$baseColorGreen;
				border: 1px solid g.$baseColorGreen;
				color: g.$white;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion ,
.sp-accordion {
	cursor: pointer;
	background: g.$baseColor;
	padding: 10px 20px;
	position: relative;
	
	&__icon {
		right: 10px;
		@include g.centering-elements(false, true);
		color: g.$baseColorGreen;
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
		.icon-add::before {
			content: "\e909";
		}
		
		.accordion__input {
			.__txt {
				&::after {
					opacity: 1;
				}
			}
		}
	}
	
	&._check_ {
		padding: 15px 20px;
		font-weight: bold;
		font-size: g.$m;
	}
	
	&__input {
		.__elem {
			display: none;
		}
		.__txt {
			position: relative;
			padding: 0 0 0 30px;
			cursor: pointer;
			&::before {
				left: 0;
				width: 14px;
				height: 14px;
				border: 2px solid #d9d9d9;
				background: g.$white;
				content: '';
				@include g.centering-elements(false, true);
			}
			&::after {
				left: 1px;
				content: '\f00c';
				color: g.$baseColorGreen;
				font-family: 'icomoon';
				font-size: g.$m;
				font-weight: 600;
				opacity: 0;
				@include g.centering-elements(false, true);
			}
		}
	}
}

.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	color: g.$white;
	background: g.$baseColorGreen;
	font-size: g.$xxs;
	@include g.centering-elements(false, true);
	@include g.radius(2);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: g.$textColor;
	font-size: g.$m;
	@include g.radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: g.$textColor;
	@include g.radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: g.$white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: g.$textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$baseColorGreen;
			@include g.circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: g.$textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: g.$baseColorGreen;
			font-family: 'icomoon';
			font-size: g.$m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	padding: 3px 20px;
	margin: 0 3px 3px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100px;
	height: 30px;
	background: g.$baseColorlighten;
	color: g.$white;
	font-size: g.$xs;
	font-weight: g.$font-regular;
	text-align: center;
	line-height: 1.4;
	@include g.radius(3);

	&--red {
		background: g.$red;
	}
	&--blue,
	&--achitanq {
		background: g.$blue
	}
	&--pink,
	&--event {
		background: g.$pink;
	}
	&--orange,
	&--principal {
		background: g.$orange;
	}
	&--purple,
	&--exam {
		background: g.$purple;
	}
	&--green,
	&--club {
		background: g.$baseColorGreen;
	}
	&--yellow,
	&--news {
		background: g.$yellow;
	}
	&--green-light {
		background: g.$baseColorlighten;
	}
	&--gray {
		background: g.$gray;
		color: g.$textColor;
	}
	&--darkgray {
		background: g.$dark-gray;
	}
	&--examinee {
		background: #37A8CC;
	}
	&--student {
		background: #DE6767;
	}
	&--circle {
		@include g.circle(40);
	}
	&--bukatsu {
		min-width: 100px;
		margin: 0 3px;
		background: g.$white;
		border: 1px solid g.$baseColorlighten;
		color: g.$baseColorlighten;
		font-weight: g.$font-regular;
		@include g.transition;
	}
	&:hover {
		text-decoration: none;
		@include g.transition;
		@include g.opacity()
	}
}
a.label--tag:hover {
	background: g.$baseColorlighten;
	border: 1px solid g.$baseColorlighten;
	color: g.$white;
	text-decoration: none;
}



/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	min-width: 0;

	&__item {
		display: flex;
		border-bottom: 1px solid g.$borderColor;
		padding: 15px 5px;
		line-height: 1.5;

		&__date {
			flex: 0 0 120px;
			margin: 0 3% 0 0;
		}
		&__body {
			flex: 1 1 auto;
			min-width: 0;
		}
		&__category {
			display: flex;
			margin: 0 0 10px;
		}
		&__text {
			a[target="_blank"] {
				&::after {
					content: "\f08e";
					font-family: 'icomoon' !important;
					margin: 0 0 0 10px;
				}
			}
		}
	}
}

.noarticle {
	border-top: 1px dotted #989898;
    border-bottom: 1px dotted #989898;
    padding: 25px 0;
	
	&__icon {
		color: g.$baseColorGreen;
		font-size: 3.8rem;
		line-height: 1.2;
		display: block;
		margin: 0 0 10px;
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		margin: 0 0 40px;
		
		&__label {
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0 50px;
			
			.label {
				margin: 0 3px 5px;
			}
		}
	}

	strong {
		font-weight: bold !important;
	}

	em {
		font-weight: bold !important;
		color: g.$red !important;
	}

	.c-multifild {
		&:not(:first-child) {
			margin-top: 40px;
		}
		&__item {
			&:not(:first-child) {
				margin-top: 30px;
			}
			&--ttl {
				& + * {
					margin-top: 10px !important;
				}
			}
		}
	}

	[data-lity="data-lity"] {
		display: block;

		&:hover {
			opacity: 0.7;
		}
	}
}



/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	position: relative;
	display: block;
	margin-bottom: 30px;

	&__img {
		position: relative;
		padding-top: 66%;
		background: #fff;
		overflow: hidden;

		&::before,
		&::after {
			position: absolute;
			content: '';
			opacity: 0;
			visibility: hidden;
			@include g.transition;
		}
		&::before {
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			z-index: 1;
			border: 1px solid rgba(g.$white, 0.4);
		}
		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(g.$baseColorGreen, .75);
		}

		img {
			@include g.centering-elements(true, true);
			@include g.transition;
		}
		&__read {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			color: g.$white;
			font-size: g.$m;
			opacity: 0;
			@include g.transition;
		}
		.label--red {
			position: absolute;
			bottom: 8px;
			right: 8px;
			max-width: calc(100% - 16px);
			padding: 4px 8px;
			color: g.$white;
			font-size: g.$xxs;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
			line-height: 1.4;
		}
	}
	&__body {
		width: 100%;
		padding: 20px;
		background: url("/common/img/common/bg_pattern03.png") 0 0 repeat;
		color: g.$textColor;
		line-height: 1.6;
		@include g.transition;

		@media screen and (max-width: 1024px) {
			padding: 10px 20px;
		}
		&__date {
			margin: 0 0 5px;
			font-size: g.$xs;
		}
		&__ttl {
			font-weight: g.$font-medium;
			@include g.literal;

			.link-arrow__text {
				overflow: hidden;
				display: block;
				height: 3.2em;
			}
		}
	}

	&__icon {
		display: flex;
		flex-wrap: wrap;
	}

	&:hover {
		text-decoration: none;

		.panel__img {
			img {
				transform: translate(-50%, -50%) scale(1.2);
			}
			&::before,
			&::after,
			&__read {
				opacity: 1;
				visibility: visible;
			}
		}
		.panel__body__date,
		.link-arrow__icon,
		.link-arrow__text {
			color: g.$baseColorGreen;
		}
	}

	.label--circle {
		position: absolute;
		right: 10px;
		bottom: 92px;
	}
}



/* --------------------------------------------------- */
/* pic-block */
/* --------------------------------------------------- */
.pic-block {
	display: flex;
	flex-direction: column;
	&__text {
		height: 100%;
		width: 86%;
		background: url("/common/img/common/bg_pattern03.png");
		margin: -40px auto 0;
		padding: 0 30px 30px;
		position: relative;
		@include g.box-shadow;
		
		&__ttl {
			color: g.$baseColorGreen;
			font-size: g.$xxl;
			text-align: center;
			margin: 0 0 15px;
			padding: 20px 0 0;
			font-weight: g.$font-medium;
			position: relative;
			line-height: 1.4;
			
			&::before {
				content: "";
				width: 60px;
				height: 40px;
				border-top: 4px solid g.$baseColorlighten;
				position: absolute;
				top: 0;
				left: calc(50% - 30px);
			}
		}
	}
}



/* ----------------------------------------------------------- */
/* students
/* ----------------------------------------------------------- */
.students {
	margin: 0 0 60px;
	padding: 50px 0 0;
	position: relative;
	
	&::before {
		content: "";
		width: 100%;
		height: 380px;
		background: url("/common/img/common/bg_stripe01.png") left top repeat;
		position: absolute;
		top: 0;
		z-index: -1;
	}
	
	&__inner {
		max-width: 1320px;
		margin: 0 auto;
		padding: 0 60px;
	}

	&__ttl {
		font-size: 3.0rem;
		font-weight: g.$font-medium;
		color: g.$textColor;

		&::after {
			display: inline-block;
			content: attr(data-text);
			font-family: 'Arial',sans-serif;
			color: g.$baseColorGreen;
			font-size: g.$m;
			font-weight: g.$font-regular;
			margin: 0 0 0 15px;
		}
	}

	&__item {
		display: flex;
		box-shadow:0px 0px 7px 3px rgba(0,0,0,0.15);

		&__category {
			width: 45%;
			background: url("/common/img/common/bg_pattern03.png");
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			&__ico {
				i {
					font-size: 70px;
				}
			}
		}

		&__link {
			width: 55%;
			background: url("/common/img/common/bg_pattern06.png");

			&__item {
				height: 95px;
				display: flex;
				color: g.$textColor;
				font-size: 1.9rem;
				font-weight: g.$font-medium;
				align-items: center;
				border-left: 1px solid g.$borderColor;
				border-bottom: 1px solid g.$borderColor;
				padding: 0 30px 0 20px;
				position: relative;
				text-shadow: 0px 0px 5px g.$white, 0px 0px 8px g.$white, 0px 0px 10px g.$white;

				&._first_ {
					background: url("/common/img/index/ico_first.png") 74% center no-repeat;
				}

				&:last-child {
					border-bottom: none;
				}

				i {
					@include g.centering-elements(false, true);
					right: 20px;
				}
			}
		}

		&--junior {
			.index-students__item__link__item {
				&:hover {
					color: g.$baseColorlighten;
					text-decoration: none;
				}
			}
		}
		&--highschool {
			.index-students__item__link__item {
				&:hover {
					color: g.$lightblue;
					text-decoration: none;
				}
			}
		}
	}
}

/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 80px;
	justify-content: center;
	&__item {
		margin: 0 10px 20px;
		min-width: auto;
		.lnav--4column & {
			width: calc((100% - 81px) / 3);
		}
		@media screen and (max-width: 1279.5px) {
			font-size: g.$m;
			padding: 10px 20px;
		}
	}
}

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	line-height: 1.5;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			height: 100px;
			padding: 20px;
			background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
			color: g.$white;
			font-size: g.$xl;
			font-weight: g.$font-medium;
		}
	}
	&__item {
		background: g.$white;
		border: 1px solid g.$borderColor;
		border-top: none;
		
		&__subttl {
			background: #4d9a57;
			color: g.$white;
		}
		
		&__link {
			display: flex;
			align-items: center;
			padding: 15px 15px 15px 18px;
			color: g.$textColor;
			
			[class^="icon-angle-"] {
				margin: 0 8px 0 0;
				color: g.$baseColorlighten;
			}
			.school_club .snav__item--school2 &,
			&.current,
			&:hover {
				background: url("/common/img/common/bg_stripe01.png");
			}
		}
	}
	&__child {
		&__item {
			border-top: 1px solid g.$borderColor;

			&__link {
				display: flex;
				align-items: center;
				padding: 13px 15px 13px 30px;
				background: #f7f7f7;
				color: g.$textColor;
				font-size: g.$s;
				
				[class^="icon-angle-"] {
					margin: 0 8px 0 0;
					color: g.$baseColorGreen;
				}
				&.current {
					font-weight: g.$font-medium;
				}
				&:hover {
					background: g.$baseColor;
				}
			}
		}
	}
}

