@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* .zaikousei_blog */
/* ============================================================ */
#alumni_blog_index {
	.student-list {
		&__block {
			margin-bottom: 30px;
			
			&__link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 20px 15px 15px 20px;
				text-align: center;
				background: url("/common/img/common/bg_pattern03.png");
				@include g.box-shadow;
				
				&:hover {
					text-decoration: none;
					background: g.$baseColorGreen;
					color: g.$white;
					
					.ttl06 {
						color: g.$white;
					}
					
					.student-list__block__txt {
						color: g.$white;
					}
					
					.student-list__block__ico {
						background: g.$white;
						color: g.$baseColorGreen;
					}
					
					.student-list__block__inner {
						&::after {
							color: g.$white;
						}
					}
				}
			}
			
			&__ico {
				font-size: 3.4rem;
				line-height: 1.4;
				background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
				@include g.radius(50);
				color: g.$white;
				width: 60px;
				height: 60px;
				margin: 0 20px 0 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			&__inner {
				text-align: left;
				width: calc(100% - 80px);
				position: relative;
				padding: 0 15px 0 0;
				
				&::after {
					content: "\f105";
					color: g.$baseColorGreen;
					font-family: 'icomoon';
					font-size: g.$xl;
					display: block;
					text-align: center;
					position: absolute;
					top: 50%;
					right: 0;
					@include g.centering-elements(false, true);
				}
			}
			
			.ttl06 {
				font-weight: 500;
				margin: 0 0 5px !important;
				padding: 0;
				font-size: g.$l;
				border-left: none;
			}
			
			&__txt {
				color: g.$textColor;
				position: relative;
				margin: 0 0 10px;
			}
		}
	}
}
