@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		max-width: none;
		padding: 0;
	}
	.row {
		&__inner {
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
		}
	}


	/* visual
	----------------------------------------------------------- */
	.visual {
		background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
		background-size: 70px;

		&__inner {
			max-width: 1200px;
			margin: 0 auto;
		}
		&__item {
			width: 100%;
			max-height: 675px;
			position: relative;
			&__pic {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img {
					width: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}

			//&__text {
				//position: absolute;
				//left: 0;
				//right: 0;
				//bottom: 0;
				//padding: 20px;
				//background: rgba(29,29,29, 0.85);
				//color: g.$white;
				//font-size: 2.3rem;
				//font-weight: g.$font-regular;
				//line-height: 1.5;
			//}
		}
	}
	
	
	/* emergency
	----------------------------------------------------------- */
	.emergency {
		border: 2px solid g.$red;
		background: g.$white;
		max-width: 1200px;
		margin: 70px auto 0;
		padding: 0;
		
		@media screen and (max-width: 1515px) {
			margin: 70px 20px -30px;
		}
		
		.u-pc-mw17p {
			background: url("/common/img/common/bg_pattern04.png") 0 0 repeat;
			color: g.$white;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.emergency-list {
			border-left: none;
			padding: 15px 25px;
			
			&__item {
				font-size: g.$l;
				color: g.$red;
				margin: 0;
				&__link {
					color: g.$red;
					text-decoration: underline;
				}
			}
		}
	}
	
	
	/* .index-info
	----------------------------------------------------------- */
	.index-info {
		&__bnr {
			margin: 0 0 60px;
			justify-content: center;
			align-content: center;
			
			&__item {
				width: 28.3%;
				margin: 0 15px;
				
				&__link {
					display: block;
				}
			}
		}
		
		&__news,
		&__event {
			&__ttl {
				display: flex;
				align-items: center;
				font-size: 2.5rem;
				font-weight: g.$font-medium;
				
				&::before {
					font-family: 'icomoon';
					color: g.$baseColorlighten;
					font-size: 5.0rem;
					line-height: 1.5;
					margin: 0 15px 0 0;
				}
				
				&::after {
					display: inline-block;
					content: attr(data-text);
					font-family: 'Arial',sans-serif;
					color: g.$baseColorlighten;
					font-size: g.$m;
					font-weight: g.$font-regular;
					margin: 10px 0 0 10px;
				}
			}
		}
		
		&__news__ttl::before {
			content: "\e902";
		}
		&__event__ttl::before {
			content: "\e903";
		}
		
		.btn {
			color: g.$baseColorlighten;
			border: 1px solid g.$baseColorlighten;
			@include g.radius(3);
			
			&:hover {
				background: g.$baseColorlighten;
				color: g.$white;
			}
		}
	}
	
	.index-life,
	.index-movie {
		padding: 60px 0 80px;
		
		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			&__ttl {
				display: flex;
				align-items: center;
				font-size: 2.5rem;
				font-weight: g.$font-medium;

				&::after {
					display: inline-block;
					content: attr(data-text);
					font-family: 'Arial',sans-serif;
					color: g.$baseColorlighten;
					font-size: g.$m;
					font-weight: g.$font-regular;
					margin: 10px 0 0 10px;
				}
			}
			
			.btn {
				color: g.$baseColorlighten;
				border: 1px solid g.$baseColorlighten;
				@include g.radius(3);

				&:hover {
					background: g.$baseColorlighten;
					color: g.$white;
				}
			}
		}
	}
	
	/* .index-movie
	----------------------------------------------------------- */
	.index-movie {
		background: #FBFBFB;
		&__item {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			overflow: hidden;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&__ttl {
			font-size: 1.5rem;
			font-weight: g.$font-medium;
			margin: 10px 0 0 0;
			line-height: 2.0rem;
		}
		&__label {
			margin: 10px 0 0 0;
			display: flex;
			flex-wrap: wrap;
			.label {
				margin: 5px 5px 0 0;
			}
		}
		&._video_ {
			background: #EBF6ED;
		}
	}
	
	/* .index-life
	----------------------------------------------------------- */
	.index-life {
		&__inst {
			&__list {
				display: flex;
				margin: 0 -1px;
				flex-wrap: wrap;
			}
			&__item {
				width: calc( (100% - 12px ) / 6 );
				margin: 0 1px;
			}
			&__link {
				display: block;
				width: 100%;
				padding-bottom: 100%;
				height: 0;
				position: relative;
				overflow: hidden;
				@include g.transition;
				&:hover {
					opacity: 0.7;
				}
				.__img {
					@include g.centering-elements(true, true);
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
		}
	}
	
	/* index-students
	----------------------------------------------------------- */
	.index-students {
		background: url("/common/img/index/bg_index-students.png") left top no-repeat;
		padding: 60px 0 0;
		
		&__ttl {
			font-size: 3.0rem;
			font-weight: g.$font-medium;
			color: g.$white;
			text-shadow: 0px 0px 10px #0b4817, 0px 0px 20px #0b4817, 0px 0px 30px #0b4817, 0px 0px 40px #0b4817, 0px 0px 50px #0b4817, 0px 0px 60px #0b4817, 0px 0px 70px #0b4817;
			
			&::after {
				display: inline-block;
				content: attr(data-text);
				font-family: 'Arial',sans-serif;
				color: #b9dcc1;
				font-size: g.$m;
				font-weight: g.$font-regular;
				margin: 0 0 0 15px;
			}
		}
		
		&__item {
			display: flex;
			box-shadow:0px 0px 7px 3px rgba(0,0,0,0.15);
			
			&__category {
				width: 45%;
				background: url("/common/img/common/bg_pattern03.png");
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				
				&__ico {
					i {
						font-size: 70px;
					}
				}
			}
			
			&__link {
				width: 55%;
				background: url("/common/img/common/bg_pattern06.png");
				
				&__item {
					height: 95px;
					display: flex;
					color: g.$textColor;
					font-size: 1.9rem;
					font-weight: g.$font-medium;
					align-items: center;
					border-left: 1px solid g.$borderColor;
					border-bottom: 1px solid g.$borderColor;
					padding: 0 0 0 20px;
					position: relative;
					text-shadow: 0px 0px 5px g.$white, 0px 0px 8px g.$white, 0px 0px 10px g.$white;
					
					&._first_ {
						background: url("/common/img/index/ico_first.png") 74% center no-repeat;
					}
					
					&:last-child {
						border-bottom: none;
					}
					
					i {
						@include g.centering-elements(false, true);
						right: 20px;
					}
				}
			}
			
			&--junior {
				.index-students__item__link__item {
					&:hover {
						color: g.$baseColorlighten;
						text-decoration: none;
					}
				}
			}
			&--highschool {
				.index-students__item__link__item {
					&:hover {
						color: g.$lightblue;
						text-decoration: none;
					}
				}
			}
		}
	}
	
		
	/* news
	----------------------------------------------------------- */
	.index-news {
		position: relative;
		overflow: hidden;
		
		&::before {
			content: "";
			width: 350px;
			height: 470px;
			background: url("/common/img/index/bg_index_news01.png") 0 0 no-repeat;
			position: absolute;
			top: 30px;
			left: 0;
		}
		
		&::after {
			content: "";
			width: 316px;
			height: 225px;
			background: url("/common/img/index/bg_index_news02.png") 0 0 no-repeat;
			position: absolute;
			top: 0;
			right: 0;
		}
		
		.row__inner {
			position: relative;
			z-index: 1;
			
			&::before {
				content: "";
				width: 337px;
				height: 187px;
				background: url("/common/img/index/bg_index_news03.png") 0 0 no-repeat;
				position: absolute;
				bottom: 40px;
				left: 0;
				z-index: -1;
			}

			&::after {
				content: "";
				width: 376px;
				height: 144px;
				background: url("/common/img/index/bg_index_news04.png") 0 0 no-repeat;
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: -1;
			}
		}
		
		&__head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			@media screen and (max-width: 1279.5px) {
				display: block;
				
				.index-news__ttl {
					margin: 0 0 15px;
				}
			}
		}
		
		&__ttl {
			font-size: 3.0rem;
			font-weight: g.$font-medium;
			
			&::after {
				display: inline-block;
				content: attr(data-text);
				font-family: 'Arial',sans-serif;
				color: g.$baseColorlighten;
				font-size: g.$m;
				font-weight: g.$font-regular;
				margin: 0 0 0 15px;
			}
		}
		
		.panel {
			box-shadow: 0px 0px 10px 0pc rgba(0,0,0,0.1);
			background: url(/common/img/common/bg_pattern03.png) 0 0 repeat;
		}
		
		.panel__icon {
			margin: 15px 0 0;

			
			.label {
				min-width: auto;
				
				&--tag {
					width: 100px;
					min-width: auto;
				}
			}
		}

		.is-animated {
			animation: .6s fade-in;
		}
		
		@media screen and (max-width: 835px) {
			.selectbox {
				&__pulldown {
					.label {
						min-width: 80px !important;
					}
				}
			}
		}
	}
	
	/* index-smallclass
	----------------------------------------------------------- */
	.index-smallclass {
		background: g.$baseColor;
		padding: 60px 0 70px;
		&__inner {
			max-width: 1420px;
		}
		&__wrap {
			max-width: 1290px;
			margin: 0 0 0 auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, auto);
			grid-column-gap: 4%;
			grid-row-gap: 0px;
		}
		&__ttl {
			font-size: 4.6rem;
			line-height: 1.6;
			font-weight: bold;
			@media screen and (max-width: 1290px) {
				font-size: 4.0rem;
			}
			.__em {
				color: #078446;
			}
			.__en {
				color: rgba(#078446,0.45);
				display: inline-block;
				margin: 0 0 0 10px;
				@include g.yellowtai;
				font-size: g.$xxxl;
			}
		}
		&__img {
			grid-column: span 2 / span 2;
			grid-row: span 2 / span 2;
			max-width: 860px;
			.__img {
				width: 100%;
			}
		}
		&__txt {
			grid-row-start: 2;
			width: 360px;
			.__txt {
				margin: 25px 0 0;
			}
			.__img {
				max-width: 360px;
				margin: 45px 0 0;
				width: 100%;
			}
		}
	}
	/* index-aq
	----------------------------------------------------------- */
	.index-aq {
		padding: 65px 0 70px;
		position: relative;
		.__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 397px;
			object-fit: cover;
			z-index: -1;
		}
		&__head {
			padding: 0 0 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&__ttl {
			font-weight: bold;
			font-size: 6.0rem;
			display: flex;
			color: g.$white;
			line-height: 1.0;
			align-items: center;
			.__sub {
				margin: 0 0 0 15px;
				background: g.$white;
				color: #2A834D;
				font-size: 1.7rem;
				font-weight: g.$font-medium;
				width: 100px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 15px;
			}
		}
		&__lead {
			color: g.$white;
			font-weight: bold;
			font-size: 3.6rem;
			text-align: right;
			.__em {
				color: #FFEC61;
				font-family: "Rock Salt", cursive;
				font-weight: 400;
				font-style: normal;
				padding: 0 5px;
			}
		}
	}
	/* index-class
	----------------------------------------------------------- */
	.index-class {
		&__head {
			position: relative;
			background: #E8F2E8;
			height: 540px;
			.__bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 55%;
				height: 540px;
				object-fit: cover;
				max-width: 1100px;
				@media screen and (max-width: 1380px) {
					width: 52%;
				}
			}
			.row__inner {
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
		}
		&__txt {
			margin-bottom: 90px;
			width: 40%;
			z-index: 1;
			@media screen and (max-width: 1380px) {
				width: 45%;
			}
		}
		&__ttl {
			font-size: 4.6rem;
			font-weight: bold;
			line-height: 1.6;
			margin: 0 0 30px;
			span {
				line-height: 1.4;
				background: g.$white;
				display: inline-block;
			}
			.__small {
				padding-left: 15px;
				font-size: 3.6rem;
			}
			.__em {
				color: g.$baseColorGreen;
			}
		}
		&__lead {
			font-weight: bold;
			font-size: 2.1rem;
			.__em {
				font-size: 2.6rem;
				color: g.$baseColorGreen;
			}
		}
		&__body {
			position: relative;
			z-index: 1;
			margin: -90px 20px 0;
			&__inner {
				margin: 0 auto;
				background: g.$white;
				max-width: 1300px;
				padding: 60px 50px 100px;
			}
		}
		.pic-block__text {
			&__ttl {
				color: g.$textColor;
				font-weight: bold;
				&::before {
					display: none;
				}
				padding: 40px 0 0;
			}
			.__label {
				background: #078446;
				color: g.$white;
				padding: 0 20px;
				border-radius: 20px;
				height: 36px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: -18px auto 0;
				white-space: nowrap;
				@include g.centering-elements(true, false);
			}
		}
		.btn {
			margin: 0 10px;
			@media screen and (max-width: 1290px) {
				margin: 0 5px;
				min-width: 200px;
			}
		}
	}
	/* index-graduate
	----------------------------------------------------------- */
	.index-graduate {
		&__head {
			padding: 70px 0;
			position: relative;
			.__bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 660px;
				object-fit: cover;
				z-index: -1;
			}
		}
		
		&__wrap {
			max-width: 1240px;
			margin: 0 auto;
			padding: 0 20px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, auto);
			grid-row-gap: 0px;
		}
		&__ttl {
			font-size: 4.6rem;
			font-weight: bold;
			line-height: 1.4;
			background: g.$white;
			display: inline-block;
			padding: 0 0 0 15px;
			@media screen and (max-width: 1290px) {
				font-size: 4.0rem;
			}
			.__em {
				color: g.$baseColorGreen;
			}
		}
		.__note {
			font-size: 1.3rem;
			margin: 10px 0 0 0;
		}
		&__img {
			grid-row: span 2 / span 2;
			text-align: right;
			padding-left: 15px;
			.__img {
				max-width: 480px;
				width: 100%;
			}
		}
		&__text {
			grid-row-start: 2;
		}
		&__lead {
			font-size: 3.0rem;
			line-height: 1.4;
			font-weight: bold;
			@media screen and (max-width: 1380px) {
				font-size: 2.8rem;
			}
			.__em {
				color: g.$baseColorGreen;
			}
			.__lage {
				font-size: 4.0rem;
			}
		}
		.btn {
			margin-top: 25px;
		}
		&__body {
			padding: 40px;
			background: g.$white;
			position: relative;
			&__ttl {
				display: flex;
				align-items: flex-start;
				font-weight: bold;
				font-size: g.$xl;
				margin: 15px 0;
				color: #078464;
				line-height: 1.6;
				.__ico {
					transform: rotate(180deg);
					color: #A7C8C0;
					font-size: g.$xxxl;
					margin: 0 10px 0 0;
				}
			}
			&__name {
				text-align: right;
				margin: 20px 0 0 0;
				font-weight: 1.3rem;
				font-weight: g.$font-medium;
				i {
					display: inline-block;
				}
				.__name {
					font-size: 1.7rem;
				}
			}
		}
		&__item {
			margin-bottom: 30px;
			&:nth-child(even) {
				.index-graduate__body__ttl {
					color: g.$baseColorGreen;
				}
			}
		}
	}
	/* index-schoollife
	----------------------------------------------------------- */
	.index-schoollife {
		position: relative;
		background: #EBF6ED;
		.__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 540px;
			object-fit: cover;
		}
		&__head {
			padding: 60px 20px 0;
			margin: 0 auto;
			z-index: 1;
			position: relative;
			max-width: 1240px;
		}
		&__ttl {
			font-size: 4.2rem;
			font-weight: bold;
			line-height: 1.4;
			background: g.$white;
			display: inline-block;
			padding: 0 0 0 15px;
			margin: 0 0 20px;
			@media screen and (max-width: 1290px) {
				font-size: 3.8rem;
			}
			.__em {
				color: g.$baseColorGreen;
			}
		}
		&__txt {
			max-width: 60%;
			.__txt {
				background: g.$white;
				line-height: 1.6;
				display: inline-block;
				margin: 0 0 5px;
				position: relative;
				z-index: 1;
			}
		}
		&__body {
			margin-top: 260px;
			padding: 40px;
			background: #EBF6ED;
			position: relative;
			@media screen and (max-width: 1290px) {
				.btn {
					min-width: auto;
					padding: 10px 20px 10px 15px;
				}
			}
		}
	}
	/* index-teacher
	----------------------------------------------------------- */
	.index-teacher {
		margin: 60px 0;
		width: 100%;
		height: 638px;
		background: url('/common/img/index/bg_index_teacher.png') repeat-x;
		background-size: 1048px 100%;
		animation: scroll-anim 50s linear infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		.__img {
			max-width: 300px;
			
		}
	}
	/* index-access
	----------------------------------------------------------- */
	.index-access {
		background: #FCFCFC;
		padding: 60px 0 80px;
		.flex-column {
			margin: 30px 0 0 0;
		}
		&__img {
			padding-right: 30px;
			flex: 1 1 auto;
			max-width: 790px;
		}
	}
}
@keyframes scroll-anim {
	100% {
		background-position: -1048px 0;
	}
}