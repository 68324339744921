@charset "utf-8";

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import url(//fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900);
@import url(//fonts.googleapis.com/css2?family=Yellowtail&display=swap);
@import url(//fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(//fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp);
@import "_reset";
@import "_icon";
@import "_lity";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_school";
	@import "pc/_education";
	@import "pc/_alumni_blog";
	@import "pc/_introduction";
	@import "pc/_student";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_school";
	@import "sp/_education";
	@import "sp/_alumni_blog";
	@import "sp/_student";
}
@import "_utility";