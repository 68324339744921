@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 要項・入試問題　#student_junior-problem */
/* ============================================================ */
#student_junior-problem {
	.exam-accordion {
		position: relative;
		
		&::after {
			content: "\f067";
			color: g.$baseColorGreen;
			font-family: 'icomoon';
			font-size: g.$l;
			display: block;
			text-align: center;
			position: absolute;
			top: 50%;
			right: 15px;
			@include g.centering-elements(false, true);
		}
		
		&:hover {
			cursor: pointer;
		}
		
		&.active {
			&::after {
				content: "\f068";
			}
		}
	}
	
	.exam-detail {
		display: none;
	}
}

/* ============================================================ */
/* よくある質問　#student_faq */
/* ============================================================ */
#student_faq {
	.faq-accordion {
		margin: 0 0 15px;
		padding: 0 30px 15px 0;
		border-bottom: 1px solid #d8dec3;
		color: g.$baseColorGreen;
		font-weight: g.$font-medium;
		line-height: 1.4;
		background: none;
		font-size: g.$m;
		display: flex;
		&::before {
			content: "Q";
			background: g.$baseColorGreen;
			color: g.$white;
			width: 30px;
			height: 30px;
			flex: 0 0 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 10px 0 0;
		}
		.accordion__icon {
			color: #777777;
			font-size: g.$xxxl;
			right: 0;
		}
		&.active {
			.accordion__icon::before {
				content: "\f056";
			}
		}
	}
	.faq-accordion-hide {
		margin: 0 0 30px;
		padding: 0;
		&__txt {
			display: flex;
			&::before {
				content: "A";
				border: 1px solid g.$baseColorGreen;
				color: g.$baseColorGreen;
				flex: 0 0 30px;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 10px 0 0;
			}			
		}
	}
}
