@charset "utf-8";
@use "../vars/_" as v;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin literal {
	font-feature-settings: "palt";
	letter-spacing: 0.04em;
}

@keyframes fade-in {
	0% { opacity: 0.1; } 
	100% { opacity: 1; }
}	

@mixin raleway {
	font-family: 'Raleway', sans-serif;
}
@mixin yellowtai {
	font-family: "Yellowtail", system-ui;
	font-weight: 400;
	font-style: normal;
}

@mixin box-shadow {
	 box-shadow: 3px 3px 7px 2px rgba(39,39,39,0.1);
 }