@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?jbhr0e');
  src:  url('/common/icon/fonts/icomoon.eot?jbhr0e#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?jbhr0e') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?jbhr0e') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?jbhr0e') format('woff'),
    url('/common/icon/fonts/icomoon.svg?jbhr0e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube-play:before {
  content: "\f16a";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-check:before {
  content: "\f00c";
}
.icon-junior:before {
  content: "\e901";
}
.icon-megaphone:before {
  content: "\e902";
}
.icon-school:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e904";
}
.icon-book:before {
  content: "\e905";
}
.icon-hs:before {
  content: "\e906";
}
.icon-jhs:before {
  content: "\e907";
}
.icon-chemistry:before {
  content: "\e908";
}
.icon-table-tennis:before {
  content: "\e909";
}
.icon-search:before {
  content: "\f002";
}
.icon-close:before {
  content: "\f00d";
}
.icon-lock:before {
  content: "\f023";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-plus:before {
  content: "\f067";
}
.icon-minus:before {
  content: "\f068";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-minus-square:before {
  content: "\f146";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}