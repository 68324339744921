@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 聖ヶ丘の教育 #education_basis_index */
/* ============================================================ */
#education_basis_index {
	.pillar-block {
		border-bottom: 1px dotted g.$borderColor;
	}
	
	.pillar {
		&__item {
			width: 280px;
			height: 280px;
			@include g.radius(140);
			border: 2px solid g.$baseColorGreen;
			text-align: center;
			position: relative;
			margin: 0 auto 30px;
			padding: 50px 0 0;
			
			&__num {
				position: absolute;
				width: 85px;
				height: 85px;
				background: url("/common/img/common/bg_pattern01.png");
				@include g.radius(44);
				color: g.$white;
				font-size: 1.5rem;
				line-height: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				top: -35px;
				left: calc(50% - 44px);
				border: 5px solid g.$white;
				
				span {
					font-family: 'Arial',sans-serif;
					font-size: 3.6rem;
					color: #faeb42;
					display: block;
					margin: 5px 0 0;
				}
			}
			
			&__ttl {
				font-size: 2.6rem;
				font-weight: g.$font-medium;
				line-height: 1.4;
				color: g.$baseColorGreen;
			}
			
			&__pic {
				border: 2px solid #fff;
				position: absolute;
				bottom: 0;
				border-radius: 0 0 140px 140px;
				overflow: hidden;
			}
		}
	}
	
	@media screen and (min-width:1280px) and (max-width: 1440px) {
		.pillar {
			&__item {
				width: 240px;
				height: 240px;
				@include g.radius(140);
				padding: 45px 0 0;
				
				&__num {
					width: 74px;
					height: 74px;
					@include g.radius(37);
					top: -38px;
					left: calc(50% - 37px);

					span {
						font-size: 2.6rem;
					}
				}
				&__ttl {
					font-size: 2.2rem;
				}
			}
		}
	}
	
	@media screen and (min-width:1024px) and (max-width: 1279px) {
		.pillar {
			&__item {
				width: 225px;
				height: 225px;
				@include g.radius(140);
				padding: 40px 0 0;
				
				&__num {
					width: 66px;
					height: 66px;
					@include g.radius(33);
					top: -35px;
					left: calc(50% - 33px);
					font-size: 1.3rem;

					span {
						font-size: 2.6rem;
					}
				}
				&__ttl {
					font-size: 2.2rem;
				}
			}
		}
	}
	
	@media screen and (min-width:959.6px) and (max-width: 1023px) {
		.pillar {
			&__item {
				width: 200px;
				height: 200px;
				@include g.radius(100);
				padding: 35px 0 0;
				
				&__num {
					width: 66px;
					height: 66px;
					@include g.radius(33);
					top: -35px;
					left: calc(50% - 33px);

					span {
						font-size: 2.4rem;
					}
				}
				&__ttl {
					font-size: 2.0rem;
				}
			}
		}
	}
	
	@media screen and (max-width: 959.5px) {
		.pillar {
			&__item {
				width: 220px;
				height: 220px;
				@include g.radius(110);
				margin: 0 auto 20px;
				padding: 40px 0 0;
				
				&__num {
					width: 65px;
					height: 65px;
					@include g.radius(33);
					top: -35px;
					left: calc(50% - 33px);

					span {
						font-size: 2.2rem;
					}
				}
				&__ttl {
					font-size: 2.3rem;
				}
			}
		}
	}
	
	
	.activities {
		&__item {
			background: url("/common/img/common/bg_pattern03.png");
			border: 3px solid #e9e9e9;
			margin: 0 0 40px;
			padding: 40px 50px;
			
			&__pic {
				position: relative;
				
				&::after {
					content: url("/common/img/education/basis/ico_activity.png");
					position: absolute;
				}
			}
			
			&__ttl {
				font-size: g.$xxl;
				font-weight: g.$font-medium;
				color: g.$baseColorGreen;
				margin: 0 0 10px;
				position: relative;
				z-index: 5;
				
				&::before {
					font-size: 8.0rem;
					color: #dfebe1;
					font-family: 'Arial',sans-serif;
					position: absolute;
					line-height: 80px;
					left: -20px;
					top: -30px;
					z-index: -1;
				}
			}
			
			&--01 {
				.activities__item__pic::after {
					top: -20px;
					right: -95px;
				}
				.activities__item__ttl::before {
					content: "01";
				}
			}
			&--02 {
				.activities__item__pic::after {
					right: -65px;
					bottom: -40px;
				}
				.activities__item__ttl::before {
					content: "02";
				}
			}
			&--03 {
				.activities__item__pic::after {
					right: -50px;
					bottom: -30px;
				}
				.activities__item__ttl::before {
					content: "03";
				}
			}
		}
	}
	
	@media screen and (min-width:1024px) and (max-width: 1440px) {
		.activities {
			&__item {
				padding: 25px;

				&__ttl {
					font-size: g.$xl;

					&::before {
						font-size: 6.5rem;
						line-height: 65px;
						left: -15px;
						top: -15px;
					}
				}
				&--01 {
					.activities__item__pic::after {
						right: -25px;
					}
				}
			}
		}
	}
	@media screen and (min-width:959.6px) and (max-width: 1025px) {
		.activities {
			&__item {
				padding: 25px;
				
				&__ttl {
					font-size: g.$xl;

					&::before {
						font-size: 6.0rem;
						line-height: 60px;
						left: -15px;
						top: -15px;
					}
				}
				&--01 {
					.activities__item__pic::after {
						right: -25px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 959.5px) {
		.activities {
			&__item {
				padding: 25px;
			}
		}
	}	
	
	
	.achitanq-summer {
		background: url("/common/img/education/basis/bg_achitanq-summer.png") 0 0 no-repeat;
		background-size: contain;
		padding: 50% 0 0;
	}
}


/* ============================================================ */
/* 各教科の指導 #education_specialties_index */
/* ============================================================ */
#education_specialties_index {
	
	.specialties-visual {
		display: flex;
		justify-content: flex-end;
		position: relative;
		
		&__pic {
			width: 90%;
		}
		
		&__catch {
			background: url(/common/img/common/bg_pattern03.png);
			color: #2e7137;
			font-size: g.$xxl;
			position: absolute;
			bottom: 30px;
			box-shadow: 0 0 7px 3px rgba(0,0,0,.15);
			left: 0;
			display: flex;
			align-items: center;
			padding: 30px 40px;
			@media screen and (min-width:1024px) and (max-width: 1440px) {
				padding: 20px 30px;
			}
			@media screen and (min-width:959.6px) and (max-width: 1025px) {
				padding: 20px;
			}
		}
	}
	
	.specialties-read {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: g.$font-medium;
		line-height: 1.4;
		font-size: 2.7rem;
		color: g.$baseColorGreen;
		margin: 0 0 45px;
		@media screen and (min-width:959.6px) and (max-width: 1440px) {
			font-size: 2.2rem;
		}
		&::before,
		&::after {
			content: '';
			width: 2px;
			height: 90px;
			background-color: g.$baseColorGreen;
		}
		&::before {
			margin-right: 40px;
			transform: rotate(-15deg);
			@media screen and (min-width:959.6px) and (max-width: 1440px) {
				margin-right: 20px;
			}
		}
		&::after {
			margin-left: 40px;
			transform: rotate(15deg);
			@media screen and (min-width:959.6px) and (max-width: 1440px) {
				margin-left: 20px;
			}
		}
		&__ruby {
			line-height: 1.8;
			ruby {
				color: g.$red;
			}
			rt {
				font-size: g.$s;
			}
		}
	}
}


/* ============================================================ */
/* A知探Qの夏 #education_activity_index */
/* ============================================================ */
#education_activity_index {
	.jhs-learning {
		&__course {
			&__item {
				border: 3px solid #e9e9e9;
				padding: 25px 20px;
				margin-bottom: 25px;
				background: url("/common/img/common/bg_pattern03.png");
				color: g.$textColor;
				
				.ttl06 {
					display: flex;
					align-items: center;
				}
				
				&__detail {
					&__img {
						margin: 0 0 15px;
					}
				}
			}
		}
	}
	.questlp-link {
		display: flex;
		justify-content: center;
		margin-top: 60px;
		&__item {
			border: 1px solid #2e7137;
		}
		
	}

}


