@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		margin-left: 0;
		margin-right: 0;
		
		.row {
			&__inner {
				padding: 0 15px;
			}
		}
	}


	/* visual
	----------------------------------------------------------- */
	.visual {
		margin: 0 0 40px;
		
		&__item {
			width: 100%;
			position: relative;
			&__pic {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img {
					width: 100%;
					height: 70vw;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}

			//&__text {
				//position: absolute;
				//left: 0;
				//right: 0;
				//bottom: 0;
				//padding: 10px;
				//background: rgba(29,29,29, 0.85);
				//color: g.$white;
				//font-size: 1.5rem;
				//font-weight: g.$font-medium;
				//line-height: 1.5;

				//p {
					//text-overflow: ellipsis;
					//white-space: nowrap;
					//overflow: hidden;
				//}
			//}
		}
	}

	
	/* emergency
	----------------------------------------------------------- */
	.emergency {
		border: 2px solid g.$red;
		background: g.$white;
		margin: 15px 15px 0 15px;
		padding: 0;
		
		.u-pc-mw17p {
			background: url("/common/img/common/bg_pattern04.png") 0 0 repeat;
			color: g.$white;
			padding: 5px 10px;
		}
		
		.emergency-list {
			border-left: none;
			padding: 10px;
			
			&__item {
				font-size: g.$xs;
				color: g.$red;
				align-items: center;
				&__link {
					color: g.$red;
					text-decoration: underline;
				}
			}
		}
	}
	


	/* .index-info
	----------------------------------------------------------- */
	.index-info {
		&__bnr {
			margin: 0 0 30px;
			
			&__item {
				margin: 0 0 15px;
				
				&__link {
					display: block;
				}
			}
		}
		
		&__news,
		&__event {
			&__ttl {
				display: block;
				align-items: center;
				font-size: 1.6rem;
				font-weight: g.$font-medium;
				position: relative;
				padding: 5px 0 0 65px;
				
				&::before {
					font-family: 'icomoon';
					color: g.$baseColorlighten;
					font-size: 5.0rem;
					line-height: 1;
					position: absolute;
					top: 0;
					left: 0;
				}
				
				&::after {
					display: block;
					content: attr(data-text);
					font-family: 'Arial',sans-serif;
					color: g.$baseColorlighten;
					font-size: g.$xxxs;
					font-weight: g.$font-regular;
					line-height: 1.2;
				}
			}
		}
		
		&__news__ttl::before {
			content: "\e902";
		}
		&__event__ttl::before {
			content: "\e903";
		}
		
		.btn {
			color: g.$baseColorlighten;
			border: 1px solid g.$baseColorlighten;
			@include g.radius(3);
			height: 35px;
		}
	}

	.index-life,
	.index-movie {
		padding: 30px 0;
		
		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__ttl {
				font-size: g.$m;
				font-weight: g.$font-medium;
				line-height: 1.4;
				
				&::after {
					display: block;
					content: attr(data-text);
					font-family: 'Arial',sans-serif;
					color: g.$baseColorlighten;
					font-size: g.$xxxs;
					font-weight: g.$font-regular;
				}
			}
		}
		.btn {
			color: g.$baseColorlighten;
			border: 1px solid g.$baseColorlighten;
			@include g.radius(3);
			height: 35px;
		}
	}
	
	/* .index-movie
	----------------------------------------------------------- */
	.index-movie {
		background: #FBFBFB;
		&__item {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			overflow: hidden;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&__ttl {
			font-size: 1.5rem;
			font-weight: g.$font-medium;
			margin: 5px 0 0 0;
			line-height: 2.0rem;
		}
		&__label {
			display: flex;
			flex-wrap: wrap;
			margin: 5px 0 0 0;
			.label {
				margin: 5px 5px 0 0;
			}
		}
		&._video_ {
			background: #EBF6ED;
		}
	}
	
	
	/* .index-life
	----------------------------------------------------------- */
	.index-life {
		&__inst {
			&__list {
				display: flex;
				margin: 0 -1px;
				flex-wrap: wrap;
			}
			&__item {
				width: calc( 100% / 3 );
			}
			&__link {
				display: block;
				width: 100%;
				padding-bottom: 100%;
				height: 0;
				position: relative;
				overflow: hidden;
				@include g.transition;
				&:hover {
					opacity: 0.7;
				}
				.__img {
					@include g.centering-elements(true, true);
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
		}
	}
	
	/* index-students
	----------------------------------------------------------- */
	.index-students {
		background: url("/common/img/index/bg_index-students.png") left top no-repeat;
		padding: 30px 0 0;
		
		&__ttl {
			font-size: 1.6rem;
			font-weight: g.$font-medium;
			color: g.$white;
			text-shadow: 0px 0px 10px #0b4817, 0px 0px 20px #0b4817, 0px 0px 30px #0b4817, 0px 0px 40px #0b4817, 0px 0px 50px #0b4817, 0px 0px 60px #0b4817, 0px 0px 70px #0b4817;
			
			&::after {
				display: block;
				content: attr(data-text);
				font-family: 'Arial',sans-serif;
				color: #b9dcc1;
				font-size: g.$xxxs;
				font-weight: g.$font-regular;
			}
		}
		
		&__item {
			display: flex;
			box-shadow:0px 0px 7px 3px rgba(0,0,0,0.15);
			
			&__category {
				width: 38%;
				background: url("/common/img/common/bg_pattern03.png");
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				
				&__ico {
					i {
						font-size: 40px;
					}
				}
			}
			
			&__link {
				width: 62%;
				background: url("/common/img/common/bg_pattern06.png");
				
				&__item {
					height: 50px;
					display: flex;
					color: g.$textColor;
					font-size: g.$s;
					font-weight: g.$font-medium;
					align-items: center;
					border-left: 1px solid g.$borderColor;
					border-bottom: 1px solid g.$borderColor;
					padding: 0 0 0 10px;
					position: relative;
					text-shadow: 0px 0px 5px g.$white, 0px 0px 8px g.$white, 0px 0px 10px g.$white;
					
					&._first_ {
						background: url(/common/img/index/ico_first.png) 10em center no-repeat;
						background-size: 26px 35px;
					}
					
					&:last-child {
						border-bottom: none;
					}
					
					i {
						@include g.centering-elements(false, true);
						right: 15px;
					}
				}
			}
		}
	}
	
	
	
	/* news
	----------------------------------------------------------- */
	.index-news {
		position: relative;
		overflow: hidden;
		
		&::before {
			content: "";
			width: 157px;
			height: 211px;
			background: url("/common/img/index/bg_index_news01.png") 0 0 no-repeat;
			background-size: 157px 211px;
			position: absolute;
			top: 10px;
			left: 0;
		}
		
		&::after {
			content: "";
			width: 142px;
			height: 101px;
			background: url("/common/img/index/bg_index_news02.png") 0 0 no-repeat;
			background-size: 142px 101px;
			position: absolute;
			top: 0;
			right: 0;
		}
		
		.row__inner {
			position: relative;
			z-index: 1;
			
			&::before {
				content: "";
				width: 152px;
				height: 84px;
				background: url("/common/img/index/bg_index_news03.png") 0 0 no-repeat;
				background-size: 152px 84px;
				position: absolute;
				bottom: 0;
				left: -15px;
				z-index: -1;
			}

			&::after {
				content: "";
				width: 170px;
				height: 65px;
				background: url("/common/img/index/bg_index_news04.png") 0 0 no-repeat;
				background-size: 170px 65px;
				position: absolute;
				bottom: 0;
				right: -15px;
				z-index: -1;
			}
		}
		
		&__ttl {
			font-size: 1.6rem;
			font-weight: g.$font-medium;
			margin: 0 0 15px;
			
			&::after {
				display: block;
				content: attr(data-text);
				font-family: 'Arial',sans-serif;
				color: g.$baseColorlighten;
				font-size: g.$xxxs;
				font-weight: g.$font-regular;
			}
		}
		
		.selectbox {
			position: relative;
			z-index: 5;

			&__select {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 15px;
				height: 48px;
				background: g.$white;
				border: 1px solid g.$borderColor;
				color: g.$textColor;
				cursor: default;
				
				&__text {
					flex: 1 1 auto;
				}
				.icon-angle-down {
					flex: 0 0 auto;
				}
				&.select-focus {
					.icon-angle-down {
						transform: rotate(180deg);
					}
				}
			}
			
			&__pulldown {
				display: none;
				position: absolute;
				left: 0;
				top: 48px;
				width: 100%;
				background: g.$baseColorGreen;
				
				& > * {
					display: block;
					padding: 10px 15px;
					color: g.$white;
					height: auto !important;
					font-size: 1.5rem !important;
					background: g.$baseColorGreen !important;
					text-align: left !important;
					
					&:not(:last-child) {
						border-bottom: 1px solid rgba(g.$white, 0.15);
					}
					&.selected {
						background: #3f520d;
						color: g.$baseColor;
					}
				}
			}
		}

		.panel {
			box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
		}
		
		.panel__icon {
			.label {
				width: 90px;
				min-width: auto;
				
				&--tag {
					width: 90px;
					min-width: auto;
				}
			}
		}
	}	
	/* index-smallclass
	----------------------------------------------------------- */
	.index-smallclass {
		background: g.$baseColor;
		padding: 40px 0 50px;
		&__ttl {
			font-size: 3.0rem;
			line-height: 1.5;
			font-weight: bold;
			.__em {
				color: #078446;
			}
			.__en {
				color: rgba(#078446,0.45);
				display: inline-block;
				margin: 0 0 0 10px;
				@include g.yellowtai;
				font-size: g.$xl;
			}
		}
		&__img {
			max-width: 100%;
			margin: 20px 0 0 0;
			.__img {
				width: 100%;
			}
		}
		&__txt {
			width: 100%;
			text-align: center;
			.__txt {
				margin: 15px 0 0;
				text-align: left;
			}
			.__img {
				max-width: 75%;
				margin: 20px auto 0;
			}
		}
	}
	/* index-aq
	----------------------------------------------------------- */
	.index-aq {
		padding: 40px 0 50px;
		position: relative;
		.__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 280px;
			object-fit: cover;
			z-index: -1;
		}
		&__head {
			padding: 0 0 30px;
			text-align: center;
		}
		&__ttl {
			font-weight: bold;
			font-size: 4.6rem;
			display: flex;
			justify-content: center;
			color: g.$white;
			line-height: 1.0;
			align-items: center;
			.__sub {
				margin: 0 0 0 15px;
				background: g.$white;
				color: #2A834D;
				font-size: g.$m;
				font-weight: g.$font-medium;
				width: 100px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 15px;
			}
		}
		&__lead {
			color: g.$white;
			font-weight: bold;
			margin: 30px 0 0;
			font-size: 2.2rem;
			.__em {
				color: #FFEC61;
				font-family: "Rock Salt", cursive;
				font-weight: 400;
				font-style: normal;
				padding: 0 3px;
			}
		}
		.pic-block__text {
			margin: -30px auto 0;
			width: 90%;
		}
		.btn--lg {
			width: 70%;
		}
	}
	/* index-class
	----------------------------------------------------------- */
	.index-class {
		&__head {
			position: relative;
			background: #E8F2E8;
			display: flex;
			flex-direction: column-reverse;
			padding: 40px 0 0;
			.__bg {
				width: 100%;
			}
			.row__inner {
				width: 100%;
			}
		}
		&__txt {
			padding: 0 0 25px;
			p {
				margin-bottom: 15px;
			}
		}
		&__ttl {
			font-size: 3.0rem;
			font-weight: bold;
			line-height: 1.6;
			margin: 0 0 20px;
			span {
				line-height: 1.4;
				background: g.$white;
				display: inline-block;
			}
			.__small {
				padding-left: 15px;
				font-size: g.$xxxl;
			}
			.__em {
				color: g.$baseColorGreen;
			}
		}
		&__lead {
			font-weight: bold;
			font-size: g.$l;
			.__em {
				font-size: g.$xl;
				color: g.$baseColorGreen;
			}
		}
		&__body {
			position: relative;
			z-index: 1;
			margin: 0 15px 0;
			&__inner {
				margin: 0 auto;
				background: g.$white;
				max-width: 1300px;
				padding: 30px 0 50px;
			}
		}
		.pic-block__text {
			margin: -30px auto 0;
			width: 90%;
			&__ttl {
				color: g.$textColor;
				font-weight: bold;
				&::before {
					display: none;
				}
				padding: 40px 0 0;
			}
			.__label {
				background: #078446;
				color: g.$white;
				padding: 0 20px;
				border-radius: 20px;
				height: 36px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin: -18px auto 0;
				white-space: nowrap;
				@include g.centering-elements(true, false);
			}
		}
		.btn--lg {
			width: 70%;
			margin: 5px 0;
		}
	}
	/* index-graduate
	----------------------------------------------------------- */
	.index-graduate {
		&__head {
			padding: 40px 0 150px;
			position: relative;
			.__bg {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				object-fit: cover;
				z-index: -1;
			}
		}
		&__wrap {
			margin: 0 auto;
			padding: 0 15px;
			width: 100%;
		}
		&__ttl {
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.4;
			background: g.$white;
			display: inline-block;
			padding: 0 0 0 15px;
			.__em {
				color: g.$baseColorGreen;
			}
		}
		.__note {
			font-size: 1.3rem;
			margin: 10px 0 0 0;
		}
		&__img {
			text-align: center;
			margin: 25px 0 0;
			.__img {
				width: 90%;
			}
		}
		&__lead {
			margin: 30px 0 15px 0;
			font-size: g.$l;
			line-height: 1.6;
			font-weight: bold;
			.__em {
				color: g.$baseColorGreen;
			}
			.__lage {
				font-size: 2.6rem;
			}
		}
		
		.btn--lg {
			width: 70%;
		}
		&__body {
			padding: 20px;
			background: g.$white;
			margin-top: -100px;
			position: relative;
			&__ttl {
				display: flex;
				align-items: flex-start;
				font-weight: bold;
				font-size: g.$xl;
				margin: 15px 0;
				color: #078464;
				line-height: 1.6;
				.__ico {
					transform: rotate(180deg);
					color: #A7C8C0;
					font-size: g.$xxl;
					margin: 0 5px 0 0;
				}
			}
			&__name {
				text-align: right;
				margin: 20px 0 0 0;
				font-weight: 1.3rem;
				font-weight: g.$font-medium;
				i {
					display: inline-block;
				}
				.__name {
					font-size: 1.7rem;
				}
			}
		}
		&__item {
			margin-bottom: 30px;
			&:nth-child(even) {
				.index-graduate__body__ttl {
					color: g.$baseColorGreen;
				}
			}
		}
	}
	/* index-schoollife
	----------------------------------------------------------- */
	.index-schoollife {
		position: relative;
		background: #EBF6ED;
		.__bg {
			display: none;
		}
		&__head {
			background: url("/common/img/index/bg_index_schoollife_sp.png") no-repeat;
			background-size: cover;
			padding: 40px 20px;
			min-height: 267px;
			position: relative;
		}
		&__txt {
			margin: 30px 0 0 0;
			.__txt {
				font-size: 1.7rem;
				margin: 0;
			}
		}
		&__ttl {
			font-size: 3.0rem;
			font-weight: bold;
			line-height: 1.7;
			display: inline-block;
			margin: 0 0 20px;
			.__em {
				color: g.$baseColorGreen;
			}
			span {
				line-height: 1.3;
				background: g.$white;
				&:first-child {
					padding: 0 15px;
				}
			}
		}
		&__body {
			margin: 30px 0 0 0;
			.pic-block__text {
				margin: -30px auto 0;
				width: 90%;
			}
			.btn {
				min-width: 60%;
			}
		}
	}
	/* index-teacher
	----------------------------------------------------------- */
	.index-teacher {
		margin: 20px 0;
		width: 100%;
		height: 470px;
		background: url('/common/img/index/bg_index_teacher.png') repeat-x;
		background-position: 0 0;
		animation: scroll-anim 50s linear infinite;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		.__img {
			max-width: 60%;
			
		}
	}
	/* index-access
	----------------------------------------------------------- */
	.index-access {
		background: #FCFCFC;
		padding: 40px 0;
		.flex-column {
			margin: 20px 0 0 0;
		}
		&__img {
			width: 100%;
		}
		.btn--lg {
			width: 70%;
		}
	}
}
@keyframes scroll-anim {
	100% {
		background-position: -1048px 0;
	}
}