@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	
	.contents__inner {
		margin: 30px 0;
	}
	
	&--column1,
	&--column2 {
		.main {
			margin: 0 15px 40px;
		}
	}
}

.row {
	padding: 30px 0;
	
	&--green {
		background: g.$baseColor;
	}
}



/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	color: g.$baseColorGreen;
	font-size: g.$m;
	font-weight: g.$font-medium;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	background: url(/common/img/common/bg_pattern02.png) 0 0 repeat;
	color: g.$baseColorGreen;
	font-size: g.$xl;
	font-weight: g.$font-medium;
	@include g.literal;
	line-height: 1.5;
	
	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 15px;
		height: 95px;
	}
	&__category-sub {
		font-size: 1.4rem;
		font-weight: g.$font-regular;
		color: #5b5b5b;
	}
}

.ttl02 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 30px;
	padding: 0 0 20px;
	color: g.$baseColorGreen;
	font-size: g.$xxl;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.3;
	@include g.literal;
	
	&::after {
		bottom: 0;
		content: '';
		width: 60px;
		border-bottom: 2px solid g.$baseColorGreen;
		@include g.centering-elements(true, false);
	}
	&__sub-ttl {
		color: g.$textColor;
		font-size: g.$s;
		font-weight: g.$font-regular;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 25px;
	font-size: g.$xl;
	font-weight: g.$font-medium;
	line-height: 1.4;
	text-align: center;
	@include g.literal;
	
	&::after {
		display: block;
		content: attr(data-text);
		margin: 4px 0 0;
		color: g.$baseColorGreen;
		font-size: g.$xxs;
		font-weight: g.$font-regular;
		text-transform: uppercase;
		letter-spacing: 0;
		@include g.raleway;
	}
}

.ttl04 {
	margin: 0 0 20px;
	padding: 15px 0;
	border-top: 1px solid g.$baseColorGreen;
	border-bottom: 1px solid #d8dec3;
	color: g.$baseColorGreen;
	font-size: g.$l;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
	
	&.accordion,
	&.sp-accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: g.$baseColorGreen;
		}
		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
}

.ttl05 {
	margin: 0 0 15px;
	padding: 10px 15px;
	background: g.$baseColor;
	font-size: g.$l;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
	
	&.accordion,
	&.sp-accordion {
		display: flex;
		align-items: center;
		
		.icon-add {
			color: g.$baseColor;
		}
		&.active {
			.icon-add::before {
				content: "\e909";
			}
		}
	}
}

.ttl06 {
	margin: 0 0 15px;
	padding: 0 0 0 15px;
	border-left: 2px solid g.$baseColorGreen;
	color: g.$baseColorGreen;
	font-size: g.$m;
	font-weight: g.$font-medium;
	line-height: 1.4;
	@include g.literal;
}

.ttl07 {
	position: relative;
	margin: 0 0 20px;
	padding: 20px 0 0;
	color: g.$baseColorGreen;
	font-size: g.$xxxl;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.4;
	@include g.literal;
	
	&::before {
		top: 0;
		display: block;
		content: '';
		width: 25px;
		height: 3px;
		background: g.$baseColorGreen;
		@include g.centering-elements(true, false);
	}
}

.ttl08 {
	margin: 0 0 15px;
	color: g.$orange;
	font-size: g.$xl;
	font-weight: g.$font-medium;
	text-align: center;
	line-height: 1.4;
	@include g.literal;
}
.underline {
	font-weight: g.$font-medium;
	background: linear-gradient(transparent 60%, #FFEB68 60%);
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;
	word-break: break-all;
	word-wrap: break-word;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2,
.column2--thin {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	word-break: break-all;
	word-wrap: break-word;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	word-break: break-all;
	word-wrap: break-word;
	
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {
	word-break: break-all;
	word-wrap: break-word;

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				top: 0.75em;
				left: 7px;
				width: 4px;
				height: 4px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: g.$textColor;
			}
		}
	}

	&--green {
		& > * {
			&::before {
				background: g.$baseColorGreen;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--border-green {
		border: 2px solid g.$baseColorGreen;
	}
	&--border-gray {
		border: 1px solid g.$borderColor;
	}
	&--green {
		background: g.$baseColor;
	}
	&--gray {
		background: #f3f3f3;
	}
	&--yellow {
		background: #f9f3d8;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid #dbdbdb;
	table-layout: fixed;
	font-size: g.$xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #dbdbdb;
		text-align: left;
		vertical-align: middle;
	}
	.bg-green {
		background: g.$baseColorGreen;
		color: g.$white;
	}
	.bg-green-lighten {
		background: g.$baseColor;
	}
	.bg-gray {
		background: #f6f6f6;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
	&--sp-thin {
		th,
		td {
			padding: 10px;
			line-height: 1.4;
		}
	}
	
	&--scroll {
		display: block;
		table-layout: fixed;
		overflow-x: scroll;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		border: none !important;
		height: auto !important;
		
		table {
			width: 200%;
		}
	}
}


.table-wrap {
	width: 100%;
	padding: 0 0 5px;
	overflow-x: auto;
	
	.table {
		width: auto;
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track{
		background: #f2f2f2;
	}
	&::-webkit-scrollbar-thumb {
		background: #c2c2c2;
	}	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 235px;
	height: 50px;
	padding: 0 30px;
	color: g.$textColor;
	font-size: g.$m;
	text-align: center;
	line-height: 1.4;
	@include g.radius(3);
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: g.$xs;
		@include g.centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	
	&--green {
		background: g.$baseColorGreen;
		border-color: g.$baseColorGreen;
		color: g.$white;
	}
	&--black {
		background: #2a2a2a;
		border-color: #2a2a2a;
		color: g.$white;
	}
	&--red {
		background: g.$red;
		border-color: g.$red;
		color: g.$white;
	}
	&--gray {
		background: g.$gray;
		border-color: g.$gray;
	}
	&--check-green {
		background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
		border-color: g.$baseColorGreen;
		color: g.$white;
		
		&:hover {
			opacity: .75;
		}
	}
	&--check-black {
		background: url("/common/img/common/bg_pattern05.png");
		border-color: #2a2a2a;
		color: g.$white;
	}
	&--lg {
		width: 100%;
		height: 60px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: g.$xxs;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: g.$textColor;
			
			&.current {
				background: g.$baseColorGreen;
				border: 1px solid g.$baseColorGreen;
				color: g.$white;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion ,
.sp-accordion {
	background: g.$baseColor;
	padding: 15px;
	position: relative;
	
	span {
		padding: 0 10px 0 0;
		display: inline-block;
	}
	
	&__icon {
		right: 10px;
		@include g.centering-elements(false, true);
		color: g.$baseColorGreen;
		font-size: g.$m;
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
		.icon-add::before {
			content: "\e909";
		}
		
		.accordion__input {
			.__txt {
				&::after {
					opacity: 1;
				}
			}
		}
	}
	
	&._check_ {
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 1.6;
	}
	
	&__input {
		.__elem {
			display: none;
		}
		.__txt {
			position: relative;
			padding: 0 0 0 30px;
			cursor: pointer;
			&::before {
				left: 0;
				width: 14px;
				height: 14px;
				border: 2px solid #d9d9d9;
				background: g.$white;
				content: '';
				@include g.centering-elements(false, true);
			}
			&::after {
				left: 2px;
				content: '\f00c';
				color: g.$baseColorGreen;
				font-family: 'icomoon';
				font-size: g.$m;
				font-weight: 600;
				opacity: 0;
				@include g.centering-elements(false, true);
			}
		}
	}
}
.accordion-hide,
.sp-accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	color: g.$white;
	background: g.$baseColorGreen;
	font-size: g.$xxs;
	@include g.centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

textarea,
select {
	color: g.$textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 3px;
	border: 1px solid #d2d2d2;
	@include g.radius(0);
	color: g.$textColor;
	font-size: 16px;
	/*font-size: g.$s;*/
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include g.radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: g.$white;
	border: 1px solid #d2d2d2;
	font-size: g.$s;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: g.$textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
			@include g.circle(14);
		}
		&::after {
			left: 5px;
			background: g.$baseColorGreen;
			@include g.circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: g.$textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken(g.$borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\e905";
			color: g.$baseColorGreen;
			font-family: 'icomoon';
			font-size: g.$m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: inline-block;
	background: g.$baseColorGreen;
	min-width: 90px;
	height: 22px;
	color: g.$white;
	font-size: g.$xxxs;
	font-weight: g.$font-medium;
	text-align: center;
	vertical-align: bottom;
	padding: 2px 5px;
	@include g.radius(2);
	
	@media screen and (max-width: 320px) {
		height: 22px;
	}

	&--red {
		background: g.$red;
	}
	&--blue,
	&--achitanq {
		background: g.$blue
	}
	&--pink,
	&--event {
		background: g.$pink;
	}
	&--orange,
	&--principal {
		background: g.$orange;
	}
	&--purple,
	&--exam {
		background: g.$purple;
	}
	&--green,
	&--club {
		background: g.$baseColorGreen;
	}
	&--yellow,
	&--news {
		background: g.$yellow;
	}
	&--green-light {
		background: g.$baseColorlighten;
	}
	&--gray {
		background: g.$gray;
		color: g.$textColor;
	}
	&--darkgray {
		background: g.$dark-gray;
	}
	&--circle {
		@include g.circle(40);
	}
	&--tag {
		background: g.$white;
		border: 1px solid g.$baseColorlighten;
		color: g.$baseColorlighten;
		font-weight: g.$font-regular;
		margin: 0 0 0 5px;
		
		&::before {
			content: "\f02b";
			font-family: 'icomoon' !important;
			margin: 0 5px 0 0;
		}
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	&__item {
		border-bottom: 1px solid g.$borderColor;
		padding: 10px 0;
		
		&__date {
			font-size: g.$xxs;
		}
		&__category {
			margin: 0 0 5px;
		}
		&__text {
			width: 100%;
			min-width: 0;
			
			a[target="_blank"] {
				&::after {
					content: "\f08e";
					font-family: 'icomoon' !important;
					margin: 0 0 0 10px;
				}
			}
		}
	}
}

.noarticle {
	border-top: 1px dotted #989898;
    border-bottom: 1px dotted #989898;
    padding: 25px 0;

	&__icon {
		color: g.$baseColorGreen;
		font-size: 3.8rem;
		line-height: 1.2;
		display: block;
		margin: 0 0 10px;
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		margin: 0 0 20px;
		
		&__label {
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0 0;
			
			.label {
				margin: 0 3px 5px;
			}
		}
	}
	strong {
		font-weight: bold !important;
	}

	em {
		font-weight: bold !important;
		color: g.$red !important;
	}

	.c-multifild {
		&:not(:first-child) {
			margin-top: 40px;
		}
		&__item {
			&:not(:first-child) {
				margin-top: 30px;
			}
			&--ttl {
				& + * {
					margin-top: 10px !important;
				}
			}
		}
	}


	[data-lity="data-lity"] {
		display: block;
	}
}



/* --------------------------------------------------- */
/* news_index */
/* --------------------------------------------------- */
#news_index {
	.panel {
		&__inner {
			background: #f1f4e8;
			box-shadow: none;
		}
		&__img {
			background: g.$white;
		}
		&__body {
			&__link {
				background: #f1f4e8;
			}
		}
	}
}



/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	position: relative;
	margin-bottom: 15px;
	background: url("/common/img/common/bg_pattern03.png") 0 0 repeat;
	
	&__img {
		position: relative;
		width: 100%;
		padding-top: 66%;
		overflow: hidden;

		img {
			width: auto !important;
			max-width: 125%;
			max-height: 175%;
			@include g.centering-elements(true, true);
		}
		.label--red {
			position: absolute;
			bottom: 8px;
			right: 8px;
			max-width: calc(100% - 16px);
			padding: 2px 8px;
			color: g.$white;
			font-size: g.$xxxs;
			font-feature-settings: 'palt';
			letter-spacing: 0.05em;
			line-height: 1.4;
		}
	}
	&__body {
		display: block;
		padding: 6px 10px 10px;
		color: g.$textColor;
		/*display: flex;
		justify-content: space-between;
		flex-direction: column;*/


		&__date {
			margin: 0 0 5px;
			font-size: 1.1rem;
		}
		&__ttl {
			font-size: g.$xxs;
			font-weight: g.$font-medium;
			@include g.literal;
			margin: 0 0 10px;

			.link-arrow__text {
			}
		}
	}

	&__icon {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		
		.label {
			width: 100% !important;
			margin: 5px 0 0 !important;
		}
	}
	.label--circle {
		position: absolute;
		right: 8px;
		bottom: 68px;

		@media screen and (max-width: 320px) {
			bottom: 60px;
		}
	}
}


/* --------------------------------------------------- */
/* pic-block */
/* --------------------------------------------------- */
.pic-block {
	&__img {
		
	}
	&__text {
		background: url("/common/img/common/bg_pattern03.png");
		margin: 25px auto 0;
		padding: 0 20px 20px;
		position: relative;
		@include g.box-shadow;
		
		&__ttl {
			color: g.$baseColorGreen;
			font-size: g.$xl;
			text-align: center;
			margin: 0 0 10px;
			padding: 15px 0 0;
			font-weight: g.$font-medium;
			position: relative;
			line-height: 1.4;
			
			&::before {
				content: "";
				width: 60px;
				height: 40px;
				border-top: 4px solid g.$baseColorlighten;
				position: absolute;
				top: 0;
				left: calc(50% - 30px);
			}
		}
	}
}



/* ----------------------------------------------------------- */
/* students
/* ----------------------------------------------------------- */
.students {
	margin: 0 0 10px;
	padding: 25px 0 0;
	position: relative;
	
	&::before {
		content: "";
		width: 100%;
		height: 380px;
		background: url("/common/img/common/bg_stripe01.png") left top repeat;
		position: absolute;
		top: 0;
		z-index: -1;
	}
	
	&__inner {
		width: 100%;
		padding: 0 15px;
	}
	
	&__ttl {
		font-size: 1.6rem;
		font-weight: g.$font-medium;
		color: g.$textColor;

		&::after {
			display: block;
			content: attr(data-text);
			font-family: 'Arial',sans-serif;
			color: g.$baseColorGreen;
			font-size: g.$xxxs;
			font-weight: g.$font-regular;
		}
	}

	&__item {
		display: flex;
		box-shadow:0px 0px 7px 3px rgba(0,0,0,0.15);

		&__category {
			width: 38%;
			background: url("/common/img/common/bg_pattern03.png");
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			&__ico {
				i {
					font-size: 40px;
				}
			}
		}

		&__link {
			width: 62%;
			background: url("/common/img/common/bg_pattern06.png");

			&__item {
				height: 50px;
				display: flex;
				color: g.$textColor;
				font-size: g.$s;
				font-weight: g.$font-medium;
				align-items: center;
				border-left: 1px solid g.$borderColor;
				border-bottom: 1px solid g.$borderColor;
				padding: 0 0 0 10px;
				position: relative;
				text-shadow: 0px 0px 5px g.$white, 0px 0px 8px g.$white, 0px 0px 10px g.$white;

				&._first_ {
					background: url(/common/img/index/ico_first.png) 10em center no-repeat;
					background-size: 26px 35px;
				}

				&:last-child {
					border-bottom: none;
				}

				i {
					@include g.centering-elements(false, true);
					right: 15px;
				}
			}
		}
	}
}
	
/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 30px;
	justify-content: center;
	&__item {
		margin: 0 5px 10px;
		min-width: auto;
		padding: 0 15px;
		width: calc((100% - 21px) / 2);
		.icon-fix-right {
			right: 5px;
			font-size: g.$xs;
		}
	}
}


/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	line-height: 1.5;
	
	&__ttl {
		&__link {
			display: flex;
			align-items: center;
			height: 70px;
			padding: 20px;
			background: url(/common/img/common/bg_pattern01.png) 0 0 repeat;
			background-size: 70px;
			color: g.$white;
			font-size: g.$l;
			font-weight: g.$font-medium;
		}
	}
	&__item {
		border: 1px solid g.$borderColor;
		border-right: none;
		border-left: none;
		border-top: none;
		
		&__subttl {
			background: #4d9a57;
			color: g.$white;
		}
		
		&__link {
			display: flex;
			align-items: center;
			padding: 15px 15px 15px 18px;
			color: g.$textColor;
			background: g.$white;
			
			[class^="icon-angle-"] {
				margin: 0 8px 0 0;
				color: g.$baseColorGreen;
			}
			.school_club .snav__item--school2 &,
			&.current,
			&:hover {
				background: url("/common/img/common/bg_stripe01.png");
				opacity: 1;
			}
		}
	}
	&__child {
		&__item {
			border-top: 1px solid g.$borderColor;

			&__link {
				display: flex;
				align-items: center;
				padding: 13px 15px 13px 30px;
				background: #f7f7f7;
				color: g.$textColor;
				font-size: g.$xs;
				
				[class^="icon-angle-"] {
					margin: 0 8px 0 0;
					color: g.$baseColorGreen;
				}
				&.current {
					font-weight: g.$font-medium;
				}
				&:hover {
					background: g.$baseColor;
					opacity: 1;
				}
			}
		}
	}
}
