@charset "utf-8";
@use "../global/_" as g;
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;

	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		font-size: calc(100vw / 102.4);
	}
}

body {
	font-family: 'Noto Sans JP', sans-serif;
}

.wrap {
	position: relative;
	display: flex;
	color: g.$textColor;
	font-size: 1.5rem;
	line-height: 1.8;
	overflow: hidden;
	
	@media screen and (max-width: 1279.5px) {
		display: block;
	}
	&__inner {
		@media screen and (min-width: 1280px) {
			width: calc(100% - 220px);
			padding: 95px 0 0;
		}
	}
}

.sp-view {
	display: none !important;
}

a {
	color: #4B8809;
	@include g.transition;
	
	&:hover {
		text-decoration: underline;
	}
	.header-logo &,
	.gnav &,
	&.sns-btn,
	.pagetop &,
	.snav &,
	.footer-sns & {
		&:hover {
			text-decoration: none;
		}
	}
}

img {
	pointer-events: none;
}

.link-arrow {
	display: flex;
	
	&__icon {
		flex: 0 0 auto;
		width: 1em;
		padding-top: 0.25em;
		color: g.$baseColorGreen;
		text-align: center;
	}
	&__text {
		flex: 0 1 auto;
		color: g.$textColor;
	}
	&--ellipsis {
		.link-arrow__text {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}

.link-opacity {
	@include g.transition;
	
	&:hover {
		@include g.opacity;
	}
}

sup {
	position: relative;
	top: -0.1em;
	font-size: 75.5%;
	vertical-align: top;
}

sub {
	position: relative;
	top: 0.1em;
	font-size: 75.5%;
	vertical-align: bottom;
}

.material-icons .__hidden {
    clip: rect(1px, 1px, 1px, 1px);
    display: block;
    position: absolute;
    top: -999999px;
    left: -999999px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    text-indent: -999999px;
}

/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	font-size: g.$xs;
	line-height: 1.4;
	border-bottom: 1px solid g.$borderColor;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1320px;
		margin: 0 auto;
		padding: 15px 60px;
		
		@media screen and (max-width: 959.5px) {
			padding: 15px 20px;
		}
	}
	&__item {
		&:not(:last-child) {
			&::after {
				margin: 0 6px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 9999;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: g.$baseColorGreen;
		color: g.$white;
		font-size: g.$l;
		@include g.transition;
		
		&:hover {
			@include g.opacity;
		}
	}
}



/* --------------------------------------------------- */
/* izimodal */
/* --------------------------------------------------- */
.modal {
	&__close {
		position: absolute;
		top: -20px;
		right: -40px;
		z-index: 10;
		color: g.$white;
		font-size: g.$xl;
		cursor: pointer;
		@include g.transition;
		
		&:hover {
			@include g.opacity;
		}
	}
}
